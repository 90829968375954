:root {
  --theme-color: #ffffff;
}

.theme-light {
  --theme-color: #ffffff;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
  font-family: 'Montserrat', sans-serif;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: grayscale;
  background: #f8f9fc;
  font-family: 'Montserrat', sans-serif;
}

html {
  scroll-behavior: smooth;
}

ul {
  margin: 0;
}

.row {
  margin: 0;
}

.logo img {
  max-height: 48px;
}

.top-nav-bar {
  padding: 12px 16px;
  background: #fff;
  height: 64px;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 9;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
}

aside {
  position: sticky;
  margin-top: 64px;
  width: 100%;
}

aside {
  color: #f5f5f5;
}

.sidebar-nav-bar {
  overflow-x: hidden;
  height: 100vh;
  overflow-y: scroll;
  padding: 16px 0;
  width: 190px;
  background-color: #4e73df;
  background-image: linear-gradient(180deg, #4e73df 10%, #224abe 100%);
  background-size: cover;
  z-index: 1;
  padding-bottom: 64px;
}

.href-color,
.href-color:hover {
  color: black;
}

ul.configuration-tab-list.projects-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.custom-column-20-dashboard-seo .sidebar-nav-bar i.fa-file,
.custom-column-20-dashboard-seo .sidebar-nav-bar i.fa-tachometer {
  width: 50px;
}

ul.configuration-tab-list.projects-list::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

ul.configuration-tab-list.projects-list::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 10px;
  background-image: -webkit-linear-gradient(90deg,
      rgba(0, 0, 0, 0.2) 25%,
      transparent);
}

.sidebar-nav-bar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.sidebar-nav-bar::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.sidebar-nav-bar::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 10px;
  background-image: -webkit-linear-gradient(90deg,
      rgba(0, 0, 0, 0.2) 25%,
      transparent);
}

#sample::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

#sample::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}

#sample::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 10px;
  background-image: -webkit-linear-gradient(90deg,
      rgba(0, 0, 0, 0.2) 25%,
      transparent);
}

.page-speed-second .ant-table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.page-speed-second .ant-table::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}

.page-speed-second .ant-table::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 10px;
  background-image: -webkit-linear-gradient(90deg,
      rgba(0, 0, 0, 0.2) 25%,
      transparent);
}

.side-menu a {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  display: block;
  padding: 12px 16px;
  font-weight: 500;
}

.side-menu a:hover,
.side-menu a:focus {
  text-decoration: none;
  color: #5a6ff3;
  color: rgba(255, 255, 255, 1);
}

.logo {
  float: left;
  width: 24%;
}

.nav-bar-center,
.nav-bar-right {
  float: left;
  width: 38%;
}


.nav-bar-right {
  text-align: right;
}

.nav-right-menu li {
  display: inline-block;
}

.nav-right-menu .dropdown-menu.show {
  display: block;
  width: 180px;
  border-radius: 0;
  top: 20px !important;
  border: none;
  padding: 0;
  font-size: 15px;
  z-index: 9;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.12);
}

.nav-right-menu .dropdown-menu.show li {
  display: block;
}

.nav-right-menu .dropdown-menu.show a {
  display: block;
  padding: 6px 8px;
  color: #abacc3;
}

.nav-right-menu .dropdown-menu.show a:hover,
.nav-right-menu .dropdown-menu.show a:focus {
  text-decoration: none;
  color: #5a6ff3;
}

.profile-pic img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 8px 0 0;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.profile-name {
  color: #abacc3;
  font-size: 16px;
  overflow: hidden;
  cursor: pointer;
}

.add-new-btnw {
  text-align: right;
}

.content-wrapper {
  width: calc(100% - 190px);
  margin: 64px 0 0 190px;
}

.dashboard-wrapper {
  padding: 40px 16px 16px;
}

.chart-bar {
  height: 24px;
  background: #163c8a;
}

.chart-bar2 {
  background: #40a009;
}

.chart-bar3 {
  background: #ff8a3f;
}

.add-monitor {
  width: 480px;
  margin: auto;
  padding: 16px;
  background: #fff;
}

.add-monitor label {
  width: 100px;
  float: left;
}

.add-monitor li {
  padding: 0 0 16px;
}

.add-monitor input,
.add-monitor select {
  width: calc(100% - 100px);
  padding: 8px;
  font-size: 16px;
  outline: none;
}

.account-profile,
.setting-info {
  padding: 16px;
  background: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.account-profile h2,
.setting-info h2 {
  margin: 16px 0;
  font-size: 21px;
  text-decoration: underline;
  font-weight: 600;
}

.account-profile label {
  display: block;
  color: #0c2690;
  font-weight: 500;
}

.account-profile li {
  padding: 0 0 8px;
}

.account-profile input {
  padding: 8px;
  font-size: 16px;
  outline: none;
  width: 75%;
}

.account-profile input[type="submit"] {
  padding: 12px 8px;
  font-size: 16px;
  outline: none;
  width: 40%;
  background: #000290;
  color: #fff;
  font-weight: 600;
  border-radius: 3px;
  outline: none;
  border: none;
  cursor: pointer;
}

.initial-msg {
  padding: 24px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.13);
  font-size: 16px;
  width: 300px;
}

.common-table .error-code {
  color: #ff3737;
}

/*Dashboard Components*/
#ctbot-wrapper {
  background: #f3f3f3;
  font-size: 16px;
}

.main-navbar {
  background: #fff;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.12), 0 0 4px 0 rgba(0, 0, 0, 0.08);
  margin: 0 0 0 80px;
  padding: 24px;
}

.dashboard-logo {
  float: left;
  position: relative;
  top: -4px;
}

.rhs-nav {
  float: right;
  width: 50%;
  text-align: right;
}

.side-navbar {
  width: 180px;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: #fff;
  text-align: left;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s;
  -webkit-transition: all all ease-in 0.3s ease-out;
  -moz-transition: all all ease-in 0.3s ease-out;
  -o-transition: all all ease-in 0.3s ease-out;
  transition: all all ease-in 0.3s ease-out;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.12), 0 0 4px 0 rgba(0, 0, 0, 0.08);
}

.side-bar-toggle {
  text-align: center;
  padding: 24px 16px 0;
}

.side-nav-links {
  padding: 88px 0 0;
}

.side-nav-links a {
  display: block;
  padding: 16px;
  text-align: left;
  color: #323232;
}

.side-nav-links a:hover {
  background: #f4f6f9;
  font-weight: 500;
  text-decoration: none;
}

.side-nav-links ul a {
  padding: 8px 32px;
  font-weight: 500;
}

.side-nav-links ul a:hover {
  background: #f4f6f9;
}

.side-nav-links li.active a {
  border-left: 3px solid #fc2779;
  padding: 16px 16px 16px 13px;
  background: #febcd6;
}

.ctbot-dashboard {
  width: calc(100% - 212px);
  margin: 80px 16px 0 196px;
}

.ctbot-top ul li {
  display: inline-block;
}

.ctbot-top {
  text-align: right;
  padding: 16px 0 0;
}

.ctbot-top select {
  width: 160px;
  padding: 8px;
  background: #fff;
  outline: none;
  -webkit-appearance: none;
  border-radius: 0;
  border: 1px solid #d9d9d9;
}

.ctbot-top input {
  width: 160px;
  padding: 8px;
  background: #fff;
  outline: none;
  border-radius: 0;
  border: 1px solid #d9d9d9;
}

.common-btn,
.common-btn:hover,
.common-btn:focus {
  padding: 10px 14px;
  text-decoration: none;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  display: inline-block;
  margin: 0 3px;
  width: 100px;
  color: #fff;
  background: #fc2779;
  border-radius: 2px;
  text-align: center;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.03);
}

.common-card {
  padding: 72px 24px;
  width: 480px;
  margin: 160px auto 0;
  border: 1px solid #d9d9d9;
  text-align: center;
  background: #fff;
  font-size: 18px;
  min-height: 240px;
}

.common-card .weak {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
}

.common-card .add-btn a {
  padding: 10px 14px;
  text-decoration: none;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  display: inline-block;
  margin: 0 3px;
  width: 100px;
  color: #fff;
  background: #fc2779;
  border-radius: 2px;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.03);
  margin: 16px 0 0;
}

.common-card h4 {
  font-size: 21px;
  font-weight: 500;
  margin: 0 0 24px;
}

.slot-fields {
  padding: 16px 0 0;
}

.slot-fields label {
  width: 160px;
  font-weight: 500;
}

.slot-fields li {
  padding: 0 0 16px;
}

.slot-fields select {
  width: 180px;
  background: #fff;
  padding: 8px;
}

.add-btn {
  position: relative;
  width: 180px;
  margin: 0 auto;
}

.add-btn input {
  position: absolute;
  top: 15px;
  left: 0;
  height: 45px;
  cursor: pointer;
  width: 100px;
  opacity: 0;
}

.file-upload .add-btn {
  display: inline-block;
  text-align: left;
}

.file-upload .add-btn a {
  margin: 0;
  text-align: center;
}

.breadcrumb-nav {
  padding: 8px 32px;
  margin: 0;
}

.breadcrumb-nav li {
  display: inline-block;
  font-size: 14px;
  margin: 0 4px 0 0;
}

.breadcrumb-nav li a,
.breadcrumb-nav li a:hover {
  color: #8c8d94;
}

.breadcrumb-nav li.active {
  font-weight: 600;
}

.dashboard-toggle {
  padding: 8px;
  border-radius: 2.1px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.toogle-wrapper {
  background: #f3f3f3;
  padding: 6px 10px;
  text-transform: uppercase;
  text-align: center;
  width: 236px;
  margin: 0 auto;
}

.toogle-wrapper a {
  padding: 10px 14px;
  color: #b0adad;
  text-decoration: none;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  display: inline-block;
  margin: 0 3px;
  width: 100px;
}

.toogle-wrapper a.active {
  color: #fff;
  background: #fc2779;
  border-radius: 2px;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.03);
}

.rhs-nav-links li {
  display: inline-block;
  margin: 0 0 0 40px;
}

.rhs-nav {
  position: relative;
  top: -8px;
}

.rhs-nav-links {
  margin: 0;
}

.noify {
  font-size: 24px;
  position: relative;
  top: 8px;
}

.notify-count {
  border: solid 2.1px #ffffff;
  background-color: #fc2779;
  color: #fff;
  width: 24px;
  height: 24px;
  display: inline-block;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  right: -16px;
  top: -4px;
}

.profile-pic img {
  width: 3rem;
  height: 3rem;
  border-radius: 0.375rem;
}

.profile-pic .avatar-display img {
  margin: 0;
}

.user-name {
  width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  color: #4c4c4c;
  font-weight: 400;
  display: inline-block;
  position: relative;
  top: 8px;
  text-align: left;
}

.filter-bar {
  border-radius: 2.1px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 16px 32px;
  color: #3f414d;
  font-size: 16px;
  font-weight: 600;
}

.apply-filter-btn,
.excel-download {
  text-transform: uppercase;
  border-radius: 2.6px;
  border: solid 1px #ebebeb;
  display: inline-block;
  padding: 12px 20px;
  cursor: pointer;
  font-weight: bold;
}

.apply-filter-btn i {
  color: #fc2779;
  font-weight: 500;
  margin: 0 10px 0 0;
  font-size: 16px;
}

.excel-download {
  text-align: right;
  color: #4c4c4c;
  font-weight: 400;
  font-size: 14px;
  height: 50px;
  background: #fff;
  margin: 0 0 8px;
  float: right;
}

.excel-download img {
  width: 27px;
  margin: 0 16px 0 0;
}

.filter-tags {
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  line-height: 1.37;
}

.filter-tags li {
  display: inline-block;
  border-radius: 90.4px;
  padding: 8px 16px;
  margin: 0 5px;
  font-weight: 400;
  border: solid 1.1px rgba(220, 220, 220, 0.8);
}

.filter-tags li.tag-label {
  border: none;
  font-weight: bold;
  font-size: 18px;
  margin: 0 5px 0 0;
  color: #3f414d;
}

.filter-tags .active {
  color: #fc2779;
  font-weight: bold;
  border-color: #fc2779;
}

.common-table {
  background-color: #fff;
  padding: 8px;
  border-radius: 4px;
}

.common-table th {
  background: #fcfcfc;
  padding: 12px 8px;
  font-size: 14px;
  font-weight: 500;
}

.common-table td {
  padding: 12px 8px;
  font-size: 12px;
  color: #000;
}

.common-table tr:hover {
  background: rgba(255, 255, 255, 0.7);
  cursor: pointer;
}

.common-table .drop-down:after {
  content: "\2807";
  font-size: 30px;
  line-height: 19px;
  font-weight: 600;
  border: none;
  position: relative;
  top: 10px;
  color: #4b4b5a;
  text-decoration: none;
  opacity: 0.6;
}

.common-table .dropdown-menu {
  right: 0 !important;
  padding: 0 !important;
  left: inherit !important;
  /* transform: translate3d(-8px, 44px, 0px) !important; */
  transform: translate3d(12px, 30px, 0px) !important;
}

.common-table .dropdown-menu>li>a:hover,
.common-table .dropdown-menu>li>a:focus {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}

.common-table .dropdown-menu a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.table-ex-btn,
.table-ex-btn:hover {
  padding: 4px 8px;
  text-decoration: none;
  border-radius: 4px;
  display: inline-block;
  background-color: #027d02;
  color: #fff;
  line-height: 16px;
  font-weight: 500;
}

.filter-fields li {
  display: inline-block;
}

.filter-fields {
  padding: 0 0 16px;
}

.common-filter {
  text-align: left;
}

.filter-fields input {
  width: 100%;
  border-radius: 2px;
  border: solid 1px #d4dee7;
  background-color: #ffffff;
  padding: 8px 16px 8px 15px;
  height: 40px;
  font-size: 16px;
  outline: none;
}

.filter-fields select {
  border-radius: 2px;
  border: solid 1px #d4dee7;
  background-color: #ffffff;
  /* color-scheme: "light" !important; */
  width: 100%;
  font-size: 14px;
  padding: 8px 16px;
  height: 40px;
  cursor: pointer;
}

.export-btn {
  text-align: right;
}

.export-btn a {
  text-transform: uppercase;
  border-radius: 2.6px;
  display: inline-block;
  padding: 8px 20px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  color: #323232;
  border: 1px solid #4e73df;
}

.common-title {
  float: left;
  font-size: 21px;
  font-weight: 600;
}

.form-title {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.13px;
  /*color: #2c8b9c;*/
  padding: 0 0 24px;
}

.common-form-fields {
  padding: 0 0 1px;
}

.common-form-fields label {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* color: #333; */
  margin: 0 0 8px;
  padding: 0;
}

.header-title {
  font-weight: 700;
}

.common-form-fields input[type="text"],
.signup-form-fields textarea {
  /* border-radius: 2px; */
  border: solid 1px #d4dee7;
  background-color: #ffffff;
  width: 100%;
  padding: 8px 16px;
  font-family: 14px;
}

.common-form-fields select {
  border-radius: 2px;
  border: solid 1px #d4dee7;
  background-color: #ffffff;
  width: 100%;
  font-size: 12px;
  padding: 8px 10px;
  /* background-image: url(/images/dashboard-select.png);
    background-size: 10px; */
}

.common-form-fields input[type="date"] {
  border-radius: 2px;
  border: solid 1px #d4dee7 !important;
  background-color: #ffffff;
  width: 100%;
  padding: 8px 16px;
  font-family: 14px;
}

.form-wrappers {
  padding: 0 16px 16px 0;
}

.common-wcard {
  background-color: #fff;
  padding: 1px;
  border-radius: 4px;
  margin: 0 0 1px;
}

.pop-over .common-wcard {
  background-color: transparent;
}

.form-action-wrapper {
  text-align: right;
  list-style-type: none;
  padding: 24px 0;
}

.form-action-wrapper li {
  display: inline-block;
  padding: 0 16px;
}

.user-common-card {
  background-color: #fff;
  width: 460px;
  margin: 80px auto 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.user-common-card .alogo {
  text-align: center;
  padding: 26px 0 8px;
}

.alogo span,
.logo span {
  display: block;
  font-size: 18px;
  font-weight: 600;
  color: #ffcb35;
}

.logo span {
  display: inline-block;
}

.form-fields {
  padding: 0 16px;
}

.user-common-card input[type="text"],
.user-common-card input[type="email"],
.user-common-card input[type="password"] {
  border: 2px solid rgba(217, 217, 217, 0.47);
  border-radius: 2px;
  padding: 8px;
  font-size: 16px;
  color: #434c5a;
  width: 100%;
  outline: none;
}

.user-common-card li {
  padding: 0 0 16px;
}

.user-common-card .btn-primary {
  width: 100%;
  text-align: center;
}

.user-moudle-btn,
.user-moudle-btn:hover {
  display: block;
  padding: 12px;
  color: #fff;
  text-decoration: none;
  background-color: #308ee6;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  margin: 8px 0;
  cursor: pointer;
}

.user-common-card .weak {
  text-align: right;
  padding: 8px 0 12px;
}

.user-msg {
  padding: 16px 0 0;
  font-weight: 500;
  font-size: 18px;
  opacity: 0.7;
}

.alogo img {
  max-height: 56px;
}

.common-subtitle {
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 8px;
}

.common-card-list {
  padding: 12px 0 0;
  margin: 0;
  list-style-type: none;
}

.common-card-list li {
  padding: 0 0 12px;
}

.common-card-list label {
  float: left;
  opacity: 0.6;
  width: 100px;
}

.graph-cnt img {
  max-width: 100%;
}

.client-view-card .common-wcard {
  min-height: 320px;
}

.common-tabs {
  border: none;
  background-color: #fff;
}

.common-tabs a,
.common-tabs a:hover,
.common-tabs a:focus {
  padding: 12px 32px !important;
  display: inline-block;
  font-size: 18px;
  color: #434c5a;
  text-decoration: none;
  border: none !important;
}

.common-tabs a:hover {
  background-color: #f7f7ff !important;
}

.common-tabs a.active {
  background-color: #4e73df;
  color: #fff;
}

.common-tab-cnt {
  padding: 24px 16px;
  background-color: #fff;
}

.modal-close {
  position: absolute;
  right: 16px;
  top: 12px;
  cursor: pointer;
  z-index: 1;
}

.modal {
  position: absolute;
  left: 0%;
  top: 40%;
}

.common-modal label {
  width: 160px;
}

.common-modal input,
.common-modal select {
  width: 180px;
}

.common-modal span {
  background-color: #f9f9f9;
  padding: 2px;
  border-radius: 4px;
  margin: 4px 0 0;
  display: inline-block;
  font-size: 11px;
}

.common-modal .common-card-list li {
  padding: 0 0 16px;
}

.ot-btn {
  background-color: #fff;
  color: #323232;
  border: 1px solid #4e73df;
}

.temp-card {
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
  padding: 4px;
  border-radius: 4px;
  text-align: center;
}

.temp-card h4 {
  font-size: 16px;
  padding: 8px 0;
  margin: 0;
  font-weight: 500;
}

.temp-card img {
  width: 100%;
}

.rsnap-lft {
  float: left;
  width: 80%;
}

.rsnap-rgt {
  float: left;
  width: 20%;
  padding: 40px 0 0;
}

.rsnap-title {
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  color: #6c757d;
  font-weight: 600;
}

.rsnap-count {
  font-size: 32px;
  font-weight: 600;
  margin: 10px 0 0;
  display: block;
}

.rsnap-rgt img {
  width: 100%;
}

.text-success,
.text-decline {
  color: #43d39e !important;
  font-size: 13px;
  /* font-weight: 700 !important; */
  text-align: center;
  padding: 2px 0 0;
}

.text-decline {
  color: #ff5c75 !important;
}

.text-successpage {
  color: #28ba2e !important;
}

.main-dashboard img {
  width: 100%;
}

.nav-bar-right .btn {
  background-color: transparent !important;
  outline: none;
}

.rg-modules {
  width: 450px;
  margin: 0 0 32px;
}

.rg-modules select {
  border-radius: 2px;
  border: solid 1px #d4dee7;
  background-color: #ffffff;
  width: 100%;
  font-size: 14px;
  padding: 8px 16px !important;
  height: 40px !important;
  cursor: pointer;
}

.rg-modules label {
  display: block;
}

.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: white;
  width: fit-content;
  border-radius: 4px;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 8px 20px;
  transition: 0.3s;
  font-size: 14px;
}

/* Change background color of buttons on hover */

/* Create an active/current tablink class */
.tab button.active {
  background-color: #4e73df;
  color: white;
  border-color: #4e73df;
}

/* Style the tab content */
.tabcontent {
  display: none;
  margin-top: 8px;
}

.tab-details-project {
  display: flex;
  padding: unset;
  margin-bottom: 24px;
  margin-top: 24px;
}

.tab-details-project li {
  list-style: none;
  margin-right: 10px;
}

button.tab-next {
  border: unset;
  padding: 0px 20px;
  background-color: unset;
  font-size: 30px;
}

.img-act-deact img {
  width: 13%;
  margin-right: 10px;
}

.img-act-deact {
  width: 14%;
}

.pie {
  width: 200px;
  height: 200px;
  background-image: conic-gradient(#7bb720 64%, red 17%);
  border-radius: 50%;
  margin-bottom: 24px;
}

.green {
  background-color: #2bb673;
}

.red {
  background-color: #d91e48;
}

.rect {
  height: 10px;
  width: 10px;
  display: inline-block;
  border-radius: 50%;
}

.pie-content {
  display: inline-block;
  margin-left: 5px;
}

.ant-table-content table tr,
.ant-table-content table tr td {
  cursor: default;
  padding-bottom: 0;
}

.ranktracking-table1 .ant-table-content table tr,
.ranktracking-table1 .ant-table-content table tr td {
  padding-bottom: 0;
}

.ranktracking-table1 .ant-table-content table tr,
.ranktracking-table1 .ant-table-content table tr td {
  cursor: default;
  padding-bottom: 0;
}


.ant-table-column-title {
  z-index: inherit;
}

.fa {
  cursor: pointer;
}

.accordian-table .fa,
.accordian-table ul.audit-titles li {
  font-size: 12px;
}

.accordian-table .fa.fa-edit {
  color: #2196f3;
}

.credit-remaining .progress-bar {
  background-color: #2196f3;
  border-radius: 0.25rem;
}

.accordian-table .ant-table-content .ant-table-thead .ant-table-cell {
  /* background-color: rgba(33, 150, 243, 0.4) !important; */
  /* background-color: #bcdefa !important; */
  margin-top: 15px;
}

/* .accordian-table .acco-btn .fa-angle-up {
	font-size: 12px;
} */



.accordian-table .accordion-item.collapsed .acco-btn .fa-angle-down,
.accordian-table .accordion-item .acco-btn .fa-angle-up {
  display: block;
}

.accordian-table .accordion-item .acco-btn .fa-angle-down,
.accordian-table .accordion-item.collapsed .acco-btn .fa-angle-up {
  display: none;
}

.accordian-table .accordion-item.test-class .acco-btn .fa-angle-up {
  display: block;
}

.accordian-table .accordion-item .acco-btn i {
  font-size: 14px;
}

.accordian-table .accordion-item .acco-btn {
  border: 0;
  background: transparent;
  padding: 0;
}

.accordian-table .ant-table {
  overflow-x: auto;
}

/* tem members */
.search {
  margin-bottom: 20px;
}

.search input.form-control {
  border-bottom: 2px solid grey !important;
  border: unset;
  border-radius: unset;
}

.search button.btn.btn-secondary {
  border: unset !important;
  color: black !important;
  background-color: unset;
  border-bottom: 2px solid grey !important;
  border-radius: unset !important;
}

i.fa.fa-search {
  font-size: 27px;
}

select#export {
  background-color: white;
  padding: 8px 20px;
  border-radius: 4px;
  color: black;
  width: unset;
}

select#maxRows {
  padding: 8px 20px;
  width: unset;
}

.data-export .count-drop {
  margin-left: 9%;
  margin-right: 24%;
}

/* .search-table-outter {
  overflow-x: scroll;
}

.search-table-outter::-webkit-scrollbar {
  width: 3px;
} */

/* Track */
/* .search-table-outter::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
} */

/* Handle */
/* .search-table-outter::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 10px;
}

.search-table-outter th,
td {
  min-width: 150px;
} */

.checkbox-table {
  min-width: 1px !important;
}

.deactivate {
  background-color: red;
  padding: 5px 36px;
  border: unset;
  border-radius: 4px;
}

.audit-titles li i.fa.fa-trash {
  font-size: 11px;
}

.modules-config .css-2b097c-container {
  width: 25em;
}

.modules-config .add-user {
  width: 100%;
}

.pagination .active {
  background-color: #4e73df;
  /* border-radius: unset; */
  color: #fff;
}

ul.pagination {
  float: right;
  border: 2px solid #ddd;
  border-left: unset;
  border-right: unset;
  color: #4e73df;
}

.pagination-container {
  margin-top: 50px;
  margin-bottom: 82px;
}

.pagination li {
  padding: 5px 15px;
  border-right: 2px solid #ddd;
  cursor: pointer;
}

li.prec {
  padding: 5px 0px;
}

.pagination li:last-child {
  border-right: unset !important;
}

.css-xb97g8 {
  color: #212529;
}

button.Import {
  border: solid 1px #d4dee7;
  background-color: white;
  padding: 8px 20px;
  border-radius: 4px;
  color: black;
}

.search input {
  background-color: unset;
}

/* toggle switch */

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 20px;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 5px;
  bottom: 5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

/* input:checked+.slider:before {
  left: -8px;
} */

input:checked+.slider {
  background-color: #50ba50;
}



input:focus+.slider {
  /* box-shadow: 0 0 1px #95ff4e; */
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  /* transform: translateX(26px); */
}

input:checked+.slider:before {
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

#piechart {
  margin-bottom: 24px;
}

#usertype {
  margin-top: 16px;
  border: 1px solid #ddd;
  background-color: unset;
  padding: 8px 14px;
  width: 100%;
  color: gray;
}

.ms-options li {
  list-style: none;
}

.ms-options ul {
  padding-left: 10px !important;
}

span.modal-title-edit {
  font-weight: bold;
  font-size: 18px;
}

.box {
  border: 1px solid #ddd;
  padding: 8px 14px;
  width: 239px;
  height: 622px;
}

span.box-name {
  font-size: 16px;
  color: gray;
}

span.box-close {
  float: right;
}

h4.box-title {
  font-size: 18px;
}

#addinp {
  border: unset;
  background-color: unset;
}

#urlinputbox,
#compinputbox {
  margin-top: 24px;
}

#urlinputs,
#compinputs {
  margin-top: 24px;
}

ul.ant-pagination.ant-table-pagination.ant-table-pagination-left .ant-select-selector {
  border: 1px solid rgb(204, 204, 204) !important;
  padding: 3px 19px !important;
  border-radius: 4px !important;
  color: black;
  height: unset !important;
}

.ant-table {
  overflow-x: auto;
}

button.ant-btn.ant-btn-icon-only.ant-input-search-button {
  border: unset;
}

.input-group-icon.widget15-compact .ant-input {
  padding-left: calc(1.5em + 0.75rem + 2px);
}

.search span.ant-input-group-wrapper.ant-input-search {
  border-bottom: 2px solid grey !important;
  border: unset;
  border-radius: unset;
  width: max-content;
}

.search svg {
  color: black;
  font-size: 25px;
}

.input-group .ant-row {
  margin-left: 128px !important;
  margin-top: 24px !important;
}

a {
  text-decoration: unset !important;
}

.portlet-title {
  font-weight: 700;
  font-size: 1.25rem;
  color: #000000 !important;
  padding-left: 15px;
}

.none {
  display: none;
}

#sample .css-yk16xz-control,
#sample .css-1pahdxg-control {
  width: 44.5em;
}

.ant-table-cell ul {
  list-style-type: none;
  font-size: 19px;
  margin-top: 24px;
}

.ant-table-cell .form-action-wrapper li::before {
  content: "";
}

.ant-table-cell .add-user {
  width: 83%;
}

.ant-table-cell li::before {
  /* content: '✓'; */
  color: #027d02;
  font-size: 19px;
  margin-right: 10px;
}

.edit-project .modal-dialog {
  max-width: 800px;
}

.edit-project .add-user {
  width: 100%;
}

.dropdown-menu li {
  display: block;
  padding: 5px 0px;
}

.dropdown-menu li a {
  color: black;
  font-size: 16px;
}

ul.dropdown-menu {
  padding: 10px;
}

.view-client .form-wrappers label {
  /* width: 40%; */
  width: 30%;
  line-height: 1.5;
}

.view-client .add-user {
  width: auto;
}

.view-client input[type="text"],
.view-client input[type="date"] {
  width: 60%;
}

.view-client select {
  width: 60%;
}

.view-client span.slider.round {
  /* width: 33%; */
  width: 57%;
}

.tab-panel-module .ant-table {
  /* width: 50%; */
  overflow: hidden;
}

#url-module-expand {
  width: max-content;
  height: 40px;
}

.score-maintain a.btn-primary {
  height: 40px;
}

.tab-panel-url select {
  border-radius: 2px;
  border: solid 1px #d4dee7;
  background-color: #ffffff;
  width: 100%;
  padding: 8px 16px;
}

.tab-panel-url input[type="text"] {
  border-radius: 2px;
  border: 1px solid #d4dee7;
  background-color: #fff;
  width: 80%;
  padding: 8px 16px;
}

.tab-panel-url-options {
  display: flex;
  /* float: right; */
  /* margin-bottom: 24px; */
}

.tab-panel-url-options span.ant-input-group-wrapper.ant-input-search {
  border-radius: 2px;
  border: solid 1px #d4dee7;
  background-color: #ffffff;
  padding: 3px 19px;
}

.row.tab-panel-url {
  margin: 48px 0px;
}

.data-export span.ant-input-group-wrapper.ant-input-search {
  width: 50%;
}

.data-export span.ant-input-group-wrapper.ant-input-search {
  border-bottom: 2px solid grey;
}

.edit-notes .modal-dialog {
  max-width: 600px;
  margin-left: auto;
  margin-right: unset;
  margin-top: unset;
  height: 100%;
}

.notes-list {
  width: 100%;
  padding: 15px 30px;
  background-color: #e3e3e3;
  margin-bottom: 24px;
  border-radius: 28px;
  font-size: 14px;
}

.edit-notes .modal-dialog {
  background-color: #fff;
}

div#url {
  /* width: 50%; */
  overflow: hidden;
}

.edit-employee-main .data-export {
  margin-left: unset;
  float: right;
}

.edit-employee-modal .add-user {
  width: 100%;
}

.edit-employee-modal .common-form-fields {
  padding: unset;
}

.data-export-client span.ant-input-group-wrapper.ant-input-search {
  width: 25%;
  border-bottom: 2px solid gray;
}

.client-list ul.ant-pagination.ant-table-pagination.ant-table-pagination-left li.ant-pagination-options {
  margin-top: -3.3%;
  margin-left: 69.5%;
}

.data-export-client select {
  padding: 8px 16px;
  border-radius: 2px;
  background-color: unset;
  border: 1px solid rgb(204, 204, 204) !important;
  margin-right: 24px;
}

.data-export-client .count-drop {
  margin-left: 2%;
  margin-right: 15%;
}

.data-export-client {
  text-align: end;
}

.sub-projects .data-export {
  text-align: end;
}

.sub-projects ul.ant-pagination.ant-table-pagination.ant-table-pagination-left li.ant-pagination-options {
  margin-left: 84%;
  margin-top: -5%;
}

.sub-projects .data-export .count-drop {
  margin-left: 9%;
  margin-right: 11%;
}

.tab-panel-module .da {
  display: flex;
}

.module-description input#inputaddurl1 {
  width: 48%;
}

input#inputaddcomp1 {
  width: 48%;
}

.tab-panel-url input:focus,
.tab-panel-url select:focus-visible {
  outline: none !important;
  border: 2px solid hsl(0, 0%, 80%) !important;
}

.ant-input-affix-wrapper {
  border: 1px solid #CBCBCB;
  border-radius: 6px;
  height: 30px;
}

select:focus-visible,
input:focus-visible,
textarea:focus-visible {
  outline: none !important;

  background-color: unset;
}

select:focus-visible {
  border: 2px solid hsl(0, 0%, 80%) !important;
}

.search input:focus-visible {
  border: 0px solid hsl(0, 0%, 80%) !important;
}

.tab-panel-url-options .ant-input:focus {
  outline: none !important;
  border: unset !important;
}

.sub-navbar {
  /* margin: 64px 0 0 190px; */
  background-color: #fff !important;
  background-image: unset !important;
  border: 1px solid black !important;
  border-radius: 4px;
}

.setting-popup.setting-page {
  height: 100vh !important;
}

.sub-navbar .side-menu a {
  color: #000;
}

.score-maintain {
  /* text-align: end; */
  float: right;
  display: flex;
}

.pa-da button.blue {
  background-color: #2196f3;
  color: #fff;
}

.pa-da button {
  border: unset;
  background-color: #fff;
  padding: 8px 16px;
}

.spam {
  margin-left: 24px;
  width: 207px;
  margin-top: 7px;
}

.da-pa-top-select input[type="date"] {
  padding: 8px 16px;
  margin-right: 24px;
}

.da-pa-top-select select {
  padding: 7px 16px;
}

div#sample-module-expand {
  overflow: hidden;
}

#url-module-expand {
  padding: 8px 16px;
}

label#fileLabel {
  font-weight: 600;
  font-size: 12px;
}

.row.da-pa-top-select {
  margin-top: 40px;
}

#dailychange {
  padding: 8px 16px;
}

.dropdown-menu.show {
  margin-top: 8px !important;
  min-width: 100px;
}

.dropdown-menu.show a {
  display: block;
  width: 100%;
}

.dropdown-menu a.dropdown-item {
  /* color: #abacc3; */
  font-size: 13px;
}

input#inputurl-view-clinet {
  border-radius: 2px;
  border: solid 1px #d4dee7;
  background-color: #f70404;
  padding: 8px 16px;
}

.row.view-client {
  background-color: #fff;
}

.multiselect-google-trends {
  display: flex;
  margin-top: 40px;
}

.multiselect-google-trends label {
  margin-right: 24px;
  margin-bottom: unset;
  margin-top: 5px;
  font-size: 14px;
}

.multiselect-google-trends select {
  border: 1px solid hsl(0, 0%, 80%);
  background-color: #ffffff;
  border-radius: 4px;
  padding: 4px 14px;
  width: 58%;
  height: 30px;
  font-size: 14px;
}

#device-type {
  border: 1px solid hsl(0, 0%, 80%);
  background-color: unset;
  border-radius: 4px;
  padding: 8px 14px;
}

select#select-url-page-speed {
  margin-right: 24px;
  min-width: 59%;
  height: 40px;
}

select {
  border: 1px solid hsl(0, 0%, 80%);
  background-color: unset;
  border-radius: 4px;
  padding: 5px 14px;
}

.tabs-inner-page-speed .react-tabs__tab {
  bottom: 0px;
  border: 1px solid #aaa;
}

.tabs-inner-page-speed ul.react-tabs__tab-list {
  border: unset;
  /* width: 20%; */
  /* padding: 2px 14px; */
  border-radius: 2.6px;
}

.tabs-inner-page-speed .react-tabs__tab.react-tabs__tab--selected {
  background-color: #2196f3;
  border-color: #2196f3;
  border-radius: unset;
  color: white;
}

input#birthdaytime {
  border-radius: 2px;
  border: solid 1px #d4dee7;
  background-color: #ffffff;
  width: 266%;
  padding: 8px 16px;
}

label.remainder-reason-label {
  width: 16% !important;
}

input#remainder-reason {
  width: 266%;
}

.module-description.google-trends-des select {
  width: 47.5%;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;
}

.module-description.google-trends-des input {
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  width: 47.5%;
}

input[type="text"]:disabled {
  color: grey;
}

input[type="text"],
textarea {
  background-color: hsl(0, 0%, 100%);
  /* border-color: #bdbdbd; */
  border-radius: 6px !important;
  border-style: solid;
  border-width: 1px;
  padding: 8px 16px;
  border: 1px solid #CBCBCB;

}

.notes-input-box {
  display: flex;
  margin-bottom: 24px;
}

button.send-button {
  border: unset;
  background-color: #4e73df;
  color: white;
  padding: 5px 10px;
  border-radius: 50%;
  margin-left: 10px;
}

.notes-input-box .profile-pic {
  margin-top: 30px;
  margin-bottom: 10px;
  margin-left: 10px;
}

div#notification-dropdown button {
  border: unset;
  color: #424242 !important;
  background: #f5f5f5 !important;
  border-color: #f5f5f5 !important;
  height: 30px;
  width: 32px;
}



div#notification-dropdown button.btn-primary {
  color: #fff;
  background: #2196f3;
  border-color: #2196f3;
}

div#notification-dropdown button.btn-primary:hover {
  color: #fff;
  background: #0c83e2;
  border-color: #0c83e2;
}


div#notification-dropdown button.btn-outline-secondary {
  color: #757575;
  border-color: #757575;
  background: #fff;
  border: 1px solid;
}

div#notification-dropdown button.btn-outline-secondary:hover {
  color: #fff;
  border-color: #9e9e9e;
  background-color: #9e9e9e;

}

.far.fa-bell,
.fa.fa-share-alt,
.fa.fa-download,
.fa.fa-filter {
  font-size: 1.25rem !important;
}

div#notification-dropdown button:hover {
  background: #2196f3 !important;
  border-color: #2196f3 !important;
  color: #fff !important;
}

div#notification-dropdown button:hover i {
  /* color: #2196f3; */
  color: #fff;
}

div#notification-dropdown .dropdown-toggle::after {
  display: none;
}

#notification-dropdown .dropdown-menu.show {
  /* width: 500px; */
  width: 25rem;
  border-radius: 2px;
  border: solid 1px #d4dee7;
  background-color: #ffffff;
  box-shadow: unset;
  transform: translate(0px, 24px) !important;
  padding: 10px;
}

.rich-list-title {
  /* font-size: 1rem; */
  line-height: 1.5;
}

.notification-item p {
  color: grey;
  margin-bottom: unset;
  font-size: 15px;
}

.avatar-label-info {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 0.25rem;
}

.rich-list-prepend .avatar-label-info {
  width: 3.25em;
  height: 3.25em;
}

.module-description ul.form-action-wrapper {
  padding: unset;
  margin: unset;
}

.module-description .common-form-fields {
  padding: 0px;
}

table.edit-project-modules {
  width: 100%;
  text-align: left;
  border-radius: 2px 2px 0 0;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: auto;
}

.edit-project-modules tbody,
.edit-project-modules td,
.edit-project-modules tfoot,
.edit-project-modules th,
.edit-project-modules thead,
.edit-project-modules tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

.edit-project-modules th {
  position: relative;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
}

.edit-project-modules thead>tr>th,
.edit-project-modules tbody>tr>td,
.edit-project-modules tfoot>tr>th,
.edit-project-modules tfoot>tr>td {
  position: relative;
  padding: 16px 16px;
  overflow-wrap: break-word;
}

.edit-project-modules thead>tr>th:not(:last-child)::before {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 1.6em;
  background-color: rgba(0, 0, 0, 0.06);
  transform: translateY(-50%);
  transition: background-color 0.3s;
  content: "";
}

.edit-project-modules tbody>tr>td {
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s;
}

.edit-project-modules .expand-button {
  margin-left: 2%;
  border: unset;
  background-color: unset;
}

.edit-project-modules .add-user {
  width: 100%;
}

.edit-project-modules .css-2b097c-container {
  width: 25em;
}

.edit-project-modules ul.input-list li:before {
  content: "✓";
  color: #027d02;
  font-size: 19px;
  margin-right: 10px;
}

ul.input-list {
  list-style: none;
  padding-left: unset;
  font-size: 17px;
}

.edit-project-modules .css-2613qy-menu {
  font-weight: 100 !important;
}

.edit-project-modules select#keywordGTFrequency,
select#UrlPSFrequency {
  width: 25em;
}

.edit-project-modules .none td {
  padding: inherit;
}

.edit-project-modules .trash-module {
  color: #0d6efd;
}

i.fa.fa-angle-right.side-dropdown {
  margin-left: 200%;
  font-size: 18px;
  font-weight: bolder;
}

.drop-dire {
  background-color: unset !important;
  border: unset !important;
}

.side-menu .dropdown-toggle::after {
  display: none !important;
}

.side-menu .dropdown-menu.show {
  background-color: unset;
  border: unset;
  padding: unset;
  margin: unset !important;
  margin-left: 5% !important;
}

.side-menu a.dropdown-item {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8) !important;
  display: block;
  padding: 12px 16px;
  font-weight: 500;
}

.side-menu .dropdown-item:focus

/* .dropdown-item:hover { */
  {
  background-color: unset !important;
  color: rgba(255, 255, 255, 0.8) !important;
}

.css-2613qy-menu {
  text-align: left !important;
  background: #fff !important;
  z-index: 99999999 !important;
  position: relative !important;
  font-size: 12px !important;
}

.side-menu .show.dropdown {
  margin-bottom: 83px;
}

.module-expand-site-uptime .da-pa-search {
  width: 32%;
}

.site-uptime-box .row {
  display: flex;
  justify-content: space-between;
}

.site-uptime-box .box-outer {
  border-radius: 4px;
  padding: 25px 25px;
  text-align: center;
  height: 100%;
  color: white !important;
  width: 100%;
  align-content: center;
  display: grid;
}

.site-uptime-box h4.box-heading {
  font-size: 14px;
  font-weight: 300;
  line-height: 23px;
  color: black !important;
  font-family: 'Montserrat', sans-serif !important;
}

.site-uptime-box p.box-value {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: unset;
  color: black;
}

.ant-table-thead>tr>th {
  background: #f1f1f1 !important;
}

.module-expand-site-uptime .da-pa-search .css-yk16xz-control {
  height: 40px;
  overflow-y: scroll;
}

.site-uptime-box .row {
  display: flex;
  justify-content: space-between;
  margin-left: 2%;
  /* margin-top: 48px; */
}

.module-expand-site-uptime div#sample-module-expand {
  overflow: hidden;
  margin-top: 48px;
  margin-bottom: 48px;
}

.module-expand-site-uptime .score-maintain {
  margin-top: 24px;
}

.rank-tracking-top-select {
  margin-bottom: 24px;
  /* margin-top: 48px !important; */
}

.rank-tracking-top-select-1 select#device-type {
  width: 32%;
}

input[type="date"] {
  background-color: #fff;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 8px 16px;
  background-position: right .90rem center;
  background-size: 15px;
  background-repeat: no-repeat;
}

.gsc-multiselect {
  width: 61%;
}

.Filter-GSC-tab label {
  color: black;
}

.Filter-GSC-tab .gsc-multiselect {
  width: 100% !important;
}

.rank-tracking-top-select .col-md-4 {
  display: flex;
}

.row.rank-tracking-top-select-2 {
  margin-top: 25px;
}

.rank-tracking-top-select-2 .col-md-4 {
  display: flex;
}

.gsc-multiselect .css-yk16xz-control {
  overflow-y: scroll;
  height: 40px;
  width: 86%;
}

.gsc-multiselect .css-tlfecz-indicatorContainer {
  display: none;
}

.box-gsc {
  /* width: 600px; */
  width: 100%;
  height: 400px;
  border: 1px solid hsl(0, 0%, 80%);
  background-color: unset;
  border-radius: 4px;
  padding: 8px 14px;
}

.box-gsc a {
  float: right;
}

.gsc-ex-title {
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 500;
}

.chart-inex {
  margin-top: 64px;
}

ul li .bold-active-enhance {
  font-size: 16px;
  font-weight: 600;
}

ul.list-enhace-headings {
  list-style: none;
  padding-left: 1em;
}

.list-enhace-headings li {
  padding: 10px 0px;

  color: grey;
}

.bold-active-enhance {
  color: black !important;
}

.enhance-active-content {
  padding: 24px 48px;
}

.module-expand-gsc .react-tabs__tab-panel {
  margin-top: 48px;
}

.module-expand-modal .modal-dialog {
  max-width: 950px;
}

.module-expand-modal .da-pa-search {
  width: 14em;
  /* height: 40px; */
}

.module-expand-modal select,
.module-expand-modal .css-1s2u09g-control,
.module-expand-modal input[type="text"],
.module-expand-modal .css-319lph-ValueContainer {
  height: 45px;
}

.module-expand-gsc-tabs-inner ul.react-tabs__tab-list li {
  /* height: 116px;
	width: 18%; */
  text-align: center;
}

.module-expand-gsc-tabs-inner .react-tabs__tab--selected h5 {
  color: #fff !important;
}

.chart-content-word-count {
  margin-top: 48px;
}

.inner-table-ga {
  padding: 10px 0px;
}

/* TicketsList CSS */
.main-title-filter {
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.apply-filter {
  border: 2px solid lightgrey;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.apply-filter:hover {
  background-color: rgba(236, 236, 236, 0.993);
  transform: scale(1.01);
}

.create-cal {
  margin-right: 10px;
}

.filterimage {
  margin-left: 8px;
}

.applyfilterimg {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  top: 0;
}

.top-ticket-frame {
  margin-top: 24px;
  padding: 15px 14px;
}

.add-user-tickets {
  width: 100%;
}

.cloudimage {
  width: 40px;
  height: 40px;
}

.notetextarea:hover {
  background-color: rgba(236, 236, 236, 0.993);
  transform: scale(1.01);
}

.summarytextarea:hover {
  border: solid 1px #d4dee7;
}

.summarytextarea {
  border: solid 1px #d4dee7;
}

.textbox1 {
  background-color: blue;
  color: white;
}

.textbox1:hover {
  background-color: blue;
}

.textbox2 {
  background-color: blue;
  color: white;
}

.textbox2:hover {
  background-color: blue;
}

.add-user-tickets .container {
  margin-left: 0px;
  padding-left: 0px;
}

/* Document Upload */
.filebutton {
  width: 120px;
  height: 40px;
  overflow: hidden;
  position: relative;
  background-color: white;
}

label span input {
  z-index: 999;
  line-height: 0;
  font-size: 50px;
  position: absolute;
  top: -2px;
  left: -700px;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: "alpha(opacity=0)";
  cursor: pointer;
  margin: 0;
  padding: 0;
}

/* end */

.team-members .data-per-page {
  width: 132px;
  margin-left: unset !important;
}

.team-members ul.ant-pagination.ant-table-pagination.ant-table-pagination-left li.ant-pagination-options {
  margin-left: 132px !important;
  margin-top: -91px !important;
}

.multiselect-graph-ga {
  margin-right: 24px;
  width: 25%;
}

.dates {
  margin-top: 24px;
  font-weight: bold;
}

.content-word-count .da-pa-search {
  width: 72% !important;
}

.website-gsc-inp {
  width: 70%;
}

.client-list .data-per-page-client {
  width: 132px;
  margin-left: unset !important;
  font-size: 16px;
}

.client-list ul.ant-pagination.ant-table-pagination.ant-table-pagination-left li.ant-pagination-options {
  margin-left: 132px !important;
  margin-top: -59px !important;
}

.sub-projects .data-per-page {
  width: 132px;
  margin-left: unset !important;
}

.sub-projects ul.ant-pagination.ant-table-pagination.ant-table-pagination-left li.ant-pagination-options {
  margin-left: 132px !important;
  margin-top: -91px !important;
}

.module-expand-gsc #aggregation {
  max-width: 52% !important;
}

.total-click-bg {
  background-color: red;
}

.date-legend-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 24px;
  margin-right: 12px;
  margin-top: 7px;
}

.date-inner {
  display: flex;
  margin-bottom: 12px;
}

.date-legend-title {
  font-weight: 500;
}

.module-expand-gsc input[type="text"],
.module-expand-gsc select {
  background-color: #fff;
}

.module-expand-keyword input[type="date"],
.module-expand-keyword select {
  width: 180px;
  padding: 5px;
}

.module-expand-keyword input[type="text"] {
  width: 180px;
  height: 30px;
}

.module-expand-keyword label {
  max-width: 100px;
  min-width: 100px;
}

.input-icons i {
  position: absolute;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
}

.icon {
  left: 260px;
}

.input-field {
  width: 100%;
  padding: 10px;
}

.module-expand-keyword .keyword-card {
  position: absolute;
  z-index: 3;
  top: -80px;
  left: 20%;
}

.module-expand-keyword .fa-search:before {
  font-size: 20px;
  content: "\f002";
  position: absolute;
  top: -5px;
  right: -40px;
}

.keyword-filter {
  left: -15px;
}

.e-multiselect.e-input-group.e-control-wrapper.e-checkbox.e-valid-input .e-multi-select-wrapper {
  border: 1px solid hsl(0, 0%, 80%);
  background-color: unset;
  border-radius: 4px;

  width: 14em;
  text-align: left;
  padding: 5px 32px 5px 14px !important;
}

.e-multi-select-wrapper {
  border: 1px solid hsl(0, 0%, 80%);
  background-color: unset;
  border-radius: 4px;
  padding-left: 14px !important;
  width: 14em;
  text-align: left;
}

.e-popup .e-checkbox-wrapper .e-frame.e-check,
.e-popup .e-checkbox-wrapper:hover .e-frame.e-check {
  background-color: #4e73df;
  color: white;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
  background: unset !important;
}

span.e-chips-close.e-close-hooker {
  display: none;
}

.site-uptime-box {
  padding: 10px 100px;
}

.da-pa-search .css-yk16xz-control {
  height: 40px;
  overflow-y: scroll;
}

.module-expand-GT .da-pa-search .css-yk16xz-control {
  min-height: 34px;
  font-size: 14px;
  height: 20px;
}

.da-pa-search .css-tlfecz-indicatorContainer {
  display: none !important;
}

#dropdown-basic {
  background-color: #4e73df;
  border-color: #4e73df;
}

.page-speed-main #device-type {
  height: 30px;
  width: 21%;
  font-size: 14px;
  padding: 4px 14px;
}

.page-speed-second .ant-table {
  overflow-x: scroll !important;
}

.module-rank .da-pa-search {
  width: 17.5% !important;
}

.rank-tracking-top-select .da-pa-search.css-2b097c-container {
  width: 65% !important;
}

.module-rank .device-type-rank-choose {
  width: 64% !important;
  height: 40px;
}

.seo-right label,
.seo-right input[type="date"] {
  margin-right: 12px;
}

.seo-right input[type="date"] {
  width: 168px;
}

.seo-right select {
  width: 57%;
}

.main-title-seo {
  margin-top: 24px;
  font-size: 18px;
  font-weight: 600;
}

text.highcharts-credits {
  display: none;
}

#chosecate {
  width: 23%;
}

.seo-main .react-tabs__tab-panel {
  margin-top: 48px !important;
}

.blue-GSC {
  /* background-color: #4e73df; */
  color: #222;
  padding: 10px 20px;
}

.blue-GSC h2,
.blue-GSC h4 {
  color: #222 !important;
}

.search-box-GSC {
  padding: 10px 20px;
}

.search-box-GSC span {
  display: flex;
}

.blue-GSC span {
  display: flex;
}

.search-results-gsc-innerbox p {
  font-size: 14px;
}

rect.highcharts-background {
  background: none;
  fill: none;
}

#atmospheric-composition {
  margin-left: -123px;
  margin-top: -103px;
}

.description-rating {
  font-size: 12px;
  line-height: 22px;
}

.table.sevolatality-border td {
  padding: 8px 15px;
}

.very-high {
  color: #d3897e;
}

.high {
  color: #e9b470;
}

.Normal {
  color: #75af65;
}

button.filter-btn-GSC {
  border: 1px solid hsl(0, 0%, 80%);
  background-color: unset;
  border-radius: 4px;
  padding: 8px 14px;
  text-align: start;
}

.module-expand-gsc label {
  width: 25%;
  margin-right: 12px;
}

.module-expand-gsc select,
.module-expand-gsc input[type="text"] {
  max-width: 52%;
}

label.aggregation-type {
  width: 25%;
  margin-right: 12px;
}

.module-expand-click-share .react-tabs__tab-panel {
  margin-top: 48px;
}

.module-expand-click-share label {
  width: 43%;
}

.module-expand-click-share input[type="text"],
.module-expand-click-share input[type="file"],
.module-expand-click-share select {
  width: 52%;
}

.module-expand-click-share .website {
  float: right;
  display: flex;
  justify-content: flex-end;
}

.website-main label {
  width: 20.5% !important;
}

.module-expand-click-share .row.website-main {
  margin-top: 36px;
  margin-bottom: 24px;
}

.website-main input[type="date"] {
  width: 24%;
  margin-right: 24px;
}

.website .da-pa-search.css-2b097c-container {
  width: 25%;
}

.module-expand-click-share .Click-Share-Keyword {
  width: 45%;
}

.module-expand-click-share label.ant-checkbox-wrapper {
  width: unset;
}

.click-share-charts .line-graph {
  margin-top: 28px;
}

button#dropdown-show-hide {
  border: 1px solid hsl(0, 0%, 80%);
  background-color: #fff;
  border-radius: 4px;
  padding: 4px 14px;
  color: black;
  font-size: 14px;
}

.website label {
  margin-top: 5px;
  margin-right: -29px;
}

.ROI .row label {
  width: 40%;
}

.ROI input[type="text"],
.ROI select {
  width: 59%;
  height: 40px;
}

.ROI .da-pa-search.css-2b097c-container {
  width: 59%;
}

.ROI .row {
  margin-bottom: 24px;
}

.ROI-outer {
  display: flex;
  border: 2px solid #ededed;
  /* padding: 10px 10px; */
  border-radius: 4px;
  margin-top: 25px;
  width: 50%;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
  background: #fff;
  box-shadow: -1px 8px 49px -10px rgba(224, 224, 224, 0.75);
  -webkit-box-shadow: -1px 8px 49px -10px rgba(224, 224, 224, 0.75);
  -moz-box-shadow: -1px 8px 49px -10px rgba(224, 224, 224, 0.75);
}

.ROI-outer p {
  font-size: 14px;
  font-weight: 600;
}

.ROI-inner {
  /* border: 2px solid #a6d5fa;
  background-color: #a6d5fa; */
  /* border-radius: 4px; */
  padding: 0px 25px;
  text-align: center;
  /* height: 70px; */
  color: white !important;
  /* width: 32%; */
}


.ROI-inner:active,
.ROI-inner.active,
.ROI-inner:hover {
  background: #2196f3;
}

.ROI-inner:active h4,
.ROI-inner.active h4,
.ROI-inner:hover h4,
.ROI-inner:active p,
.ROI-inner.active p,
.ROI-inner:hover p {
  color: #fff !important;
}

.ROI-inner h4 {
  font-size: 16px;
  font-weight: 600;
  color: black !important;
}

.ROI p {
  font-size: 19px;
  font-weight: 300;
  margin-bottom: unset;
}

.ROI-modal .add-user {
  width: 40%;
}

.ROI .add-new-btnw {
  margin-bottom: 24px;
}

.ROI .react-tabs__tab-panel {
  margin-top: 48px;
}

.ROI .main-title {
  font-size: 18px;
  margin-left: unset;
}

.ModuleExpandBacklinks select {
  width: fit-content;
  height: 40px;
}

.ModuleExpandBacklinks input {
  width: 100%;
  height: 40px;
}

.module-organic-research .container {
  display: flex;
  justify-content: space-around;
  background-color: white;
  box-shadow: gray;
  border: #000;
}

.module-organic-research .divider {
  border-left: 1px solid black;
}

.module-organic-research table#or-module-expand {
  overflow: visible;
}

.uncontrolled button {
  background-color: unset !important;
  border: unset !important;
}

.uncontrolled a {
  padding: unset;
}

.uncontrolled i.fa.fa-angle-right {
  margin-left: 76%;
  font-size: 18px;
  font-weight: bolder;
}

.uncontrolled .dropdown-menu.show {
  color: #ffffff !important;
  left: 35% !important;
  line-height: 26px;
  font-size: 15px;
}

.uncontrolled .dropdown-menu.show button {
  color: #fff !important;
}

.chart-legend-listing {
  display: flex;
  justify-content: space-around;
  margin-top: 64px;
}

.chart-legend-listing hr:not([size]) {
  height: 2px;
  opacity: unset;
}

.legend-list {
  width: 14%;
  text-align: center;
}

.legend-list-inner p {
  height: 51%;
}

.legend-list h1 {
  font-size: 24px;
}

.main-dashboard i.fa.fa-star {
  color: goldenrod;
  margin-right: 10px;
}

.audit-score-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 24px;
}

.full-report a {
  margin-top: 24px;
  color: blue !important;
}

.tickets .audit-score-title {
  margin-top: 24px;
}

.tickets-heading {
  margin-bottom: 32px;
}

.main-dashboard .high {
  color: red;
}

.main-dashboard .Medium {
  color: orange;
}

.main-dashboard .OverDue {
  color: red;
}

.main-dashboard .Inprogress {
  color: yellow;
}

.main-dashboard .today {
  color: green;
}

.ant-breadcrumb {
  margin-bottom: 24px !important;
}

.main-dashboard .btn-check:active+.btn-secondary:focus,
.btn-check:checked+.btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: unset !important;
}

.main-dashboard .btn-check:focus+.btn-secondary,
.btn-secondary:focus {
  box-shadow: unset !important;
}

.full-report {
  margin-top: 24px;
  margin-bottom: 24px;
}

.legend-list-inner {
  height: 77%;
}

.ModuleExpandDesignation .designation-box {
  border-radius: 5px;
  height: max-content;
  /* border: 1px solid #e9e9e9; */
  margin: 20px 20px;
}

.ModuleExpandDesignation select {
  border: 1px solid #777777 !important;
  /* width: 31.25rem !important; */
  height: 3rem;
  color: #000000;
  font-weight: 400;
  font-size: 1.125rem;
}

.designation-box h4 {
  font-size: 14px;
  font-weight: 500;
}

.ModuleExpandDesignation h2 {
  font-size: 21px;
  text-transform: uppercase;
}

div#full {
  width: 100%;
}

.main-dashboard .charts-flex {
  display: flex;
  margin-bottom: 48px;
}

.charts-flex .charts-main-box {
  box-shadow: 7px 20px 24px #bebebe, -47px -20px 60px #ffffff;
  margin-right: 16px;
  width: 48%;
  margin-left: 17px;
}

.charts-main-box {
  box-shadow: 7px 20px 24px #bebebe, -47px -20px 60px #ffffff;
  padding-bottom: 8px;
  padding-left: 21px;
  padding-top: 10px;
  margin-bottom: 47px;
}

.charts-flex .full-report {
  padding-left: 50px;
}

.dashboard-sales-chart1 {
  display: flex;
  margin-top: auto !important;
  margin-bottom: auto;
}

.dashboard-sales-chart1-inner {
  margin-right: 24px;
  /* display: flex; */
  background-color: #fff;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 8px 16px;
}

.dashboard-sales #atmospheric-composition {
  margin-left: -43%;
  margin-top: -156px;
}

.dashboard-sales-chart1-inner i {
  margin-right: 17px;
  font-size: 32px;
  margin-top: 5%;
}

.dashboard-sales-chart1-inner h2 {
  font-size: 26px;
  margin-top: 6px;
}

.salesRevenue .apexcharts-toolbar {
  display: none;
}

.dashboard-sales-legend {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 48px;
}

span.circle-legend {
  width: 10px;
  height: 10px;
  background-color: #4e73df;
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
}

.dashboard-sales-legend div {
  margin-right: 24px;
}

span.line-legend {
  display: inline-block;
  width: 19px;
  height: 5px;
  background-color: #a5c559;
  margin-right: 10px;
}

.dashboard-sales .chart-legend-listing {
  width: 51%;
}

span.square-legend {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

#wi-50 {
  width: 50%;
}

.daterangepicker .active {
  padding: unset;
}

.uncontrolled.btn-group.show {
  margin-bottom: 13em;
}

.uncontrolled .main {
  margin-left: -15% !important;
}

.client-list .common-table,
.sub-projects .common-table {
  padding-top: 25px;
}

.view-client-tab-3 .css-2b097c-container {
  position: relative;
  box-sizing: border-box;
  width: 25em;
  text-align: left;
}

.ticket-list .canvasjs-chart-toolbar {
  display: none;
}

.ticket-list a.canvasjs-chart-credit {
  display: none;
}

.module-expand-da select,
.module-expand-da input [type="text"] {
  background-color: #fff;
}

.module-expand-da .custom-column-80-dashboard-seo,
.module-organic-research .custom-column-80-dashboard-seo,
.seo-main.dashboard-seo .custom-column-80-dashboard-seo,
.ModuleExpandBacklinks .custom-column-80-dashboard-seo {
  padding-left: 90px;
  padding-right: 30px;
}

.module-expand-da .custom-column-80-dashboard-seo,
.module-organic-research .custom-column-80-dashboard-seo,
.seo-main.dashboard-seo .custom-column-80-dashboard-seo,
.ModuleExpandBacklinks .custom-column-80-dashboard-seo {
  padding-left: 90px;
  padding-right: 30px;
}

.daterange-picker-card input[type="checkbox"] {
  width: auto;
}

.href-color,
.href-color:hover {
  color: black;
}

.module-expand-GT select,
.module-expand-GT input [type="text"] {
  background-color: #fff;
}

.page-speed-main select,
.page-speed-main input [type="text"] {
  background-color: #fff !important;
}

.module-expand-click-share input[type="text"]#date-picker {
  max-width: 38% !important;
  width: 100%;
}

.module-expand-click-share input [type="text"],
.module-expand-click-share select {
  background-color: #fff;
}

.module-rank input[type="text"],
.module-rank select {
  background-color: #fff !important;
}

.ROI input[type="text"],
.ROI select {
  background: #fff;
}

.content-word-count .rank-tracking-top-select .col-md-5 {
  display: flex;
}

.ModuleExpandBacklinks input[type="text"],
.ModuleExpandBacklinks select {
  background: #fff !important;
}

.module-organic-research input[type="text"],
.module-organic-research select {
  background: #fff !important;
}

#input-s:focus-visible {
  /* border: unset !important; */
  outline: none;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #40a9ff;
  box-shadow: none;
  /* border-right-width: 0px; */
  outline: 0;
}

.seo-right input#date-picker {
  max-width: 59%;
}

.team-members input:checked+.slider {
  background-color: #95ff4e;
}

/* Export drop down */
.team-members .dropdown-menu a:hover {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  background-color: #4e73df;
  line-height: 1.42857143;
  color: #000;
  white-space: nowrap;
}

.edit-employee-main .dropdown-menu a:hover {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  background-color: #4e73df;
  line-height: 1.42857143;
  color: #000;
  white-space: nowrap;
}

.google-btn img {
  height: 50px;
  width: 100px;
  outline-style: solid;
  outline-color: grey;
  outline-width: 2px;
}

.profile-input {
  width: 500px;
  margin-left: 220px;
}

#dropdownMenu1 button:focus {
  outline: unset !important;
  box-shadow: unset !important;
}

#notification-dropdown button:focus {
  outline: unset !important;
  box-shadow: unset !important;
}

.tickets {
  border: 1px solid lightgray;
  padding: 45px;
  margin-left: 9px;
  width: 39% !important;
  height: fit-content;
}

.add-new-btnw.max-min {
  margin-bottom: 24px;
}

.btn-check:focus+.btn,
.btn:focus {
  box-shadow: unset !important;
}

/* #d2ecfd */
#sample-module-expand-hidden {
  overflow: hidden;
}

#sample-module-expand-hidden thead {
  display: none;
}

h2.mt-4.keyword-idea-title {
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
}

.module-expand-da .line-graph {
  margin-top: 64px;
}

/* side nav bar  */

.rs-sidenav-default .rs-sidenav-item,
.rs-sidenav-default .rs-dropdown-toggle {
  background-color: unset !important;
}

.rs-sidenav-default {
  background-color: unset !important;
  color: rgba(255, 255, 255, 0.8) !important;
}

.rs-sidenav-nav>.rs-sidenav-item,
.rs-sidenav-nav>.rs-dropdown .rs-dropdown-toggle {
  font-size: 14px !important;
  display: block !important;
  padding: 12px 16px !important;
  font-weight: 500 !important;
}

.rs-sidenav-default .rs-sidenav-item:hover,
.rs-sidenav-default .rs-dropdown .rs-dropdown-toggle:hover,
.rs-sidenav-default .rs-dropdown.rs-dropdown-open .rs-dropdown-toggle {
  color: rgba(255, 255, 255, 0.8) !important;
}

.rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item:not(.rs-dropdown-item-submenu),
.rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item:not(.rs-dropdown-item-submenu),
.rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item>.rs-dropdown-menu-toggle,
.rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item>.rs-dropdown-menu-toggle,
.rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item>.rs-dropdown-item-toggle,
.rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item>.rs-dropdown-item-toggle {
  color: rgba(255, 255, 255, 0.8) !important;
}

.rs-sidenav-nav>.rs-dropdown-focus .rs-dropdown-toggle,
.rs-sidenav-nav .rs-dropdown-toggle:focus-visible,
.rs-btn:focus {
  outline: unset !important;
  box-shadow: unset !important;
}

.rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item:not(.rs-dropdown-item-submenu):hover,
.rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item:not(.rs-dropdown-item-submenu):hover,
.rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item>.rs-dropdown-menu-toggle:hover,
.rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item>.rs-dropdown-menu-toggle:hover,
.rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item>.rs-dropdown-item-toggle:hover,
.rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item>.rs-dropdown-item-toggle:hover,
.rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item:not(.rs-dropdown-item-submenu):focus,
.rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item:not(.rs-dropdown-item-submenu):focus,
.rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item>.rs-dropdown-menu-toggle:focus,
.rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item>.rs-dropdown-menu-toggle:focus,
.rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item>.rs-dropdown-item-toggle:focus,
.rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item>.rs-dropdown-item-toggle:focus,
.rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item:not(.rs-dropdown-item-submenu).rs-dropdown-item-focus,
.rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item:not(.rs-dropdown-item-submenu).rs-dropdown-item-focus,
.rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item>.rs-dropdown-menu-toggle.rs-dropdown-item-focus,
.rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item>.rs-dropdown-menu-toggle.rs-dropdown-item-focus,
.rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item>.rs-dropdown-item-toggle.rs-dropdown-item-focus,
.rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item>.rs-dropdown-item-toggle.rs-dropdown-item-focus {
  background-color: unset !important;
}

.rs-sidenav-nav .rs-dropdown-item-toggle:focus-visible {
  outline: unset !important;
  box-shadow: unset !important;
}

.module-expand-GT #sample-module-expand {
  margin-top: 24px;
  overflow: hidden;
  margin-bottom: 24px;
}

.module-expand-site-uptime .table-graph-modules {
  width: 100% !important;
}

.common-ml-24 {
  margin-left: 24px;
}

.common-height-40 {
  height: 40px;
}

.description-box-outer {
  border-radius: 2px;
  border: solid 1px #d4dee7;
  background-color: #ffffff;
  width: 100%;
  padding: 8px 16px;
  margin-bottom: 32px;
}

.description-box-outer textarea {
  border: unset;
  width: 100%;
}

.images-description img {
  width: 50px;
  height: 50px;
  margin-right: 24px;
}

.seo-audit .astext {
  background: none;
  border: none;
  margin: 0;
  cursor: pointer;
  padding: 3px 12px;
}

.seo-audit .custom-modal-styling-title {
  width: 80%;
}

.seo-audit {
  overflow-x: hidden;
}

.seo-audit-modal .modal-content .add-user {
  width: 100%;
}

.seo-audit .yes {
  /* text-transform: uppercase; */
  border-radius: 2.6px;
  display: inline-block;
  padding: 3px 12px;
  cursor: pointer;
  /* font-weight: 500; */
  color: #000;
  /* font-size: 14px; */
  color: #fff;
  background-color: #4e73df;
  border-color: #4e73df;
}

.seo-audit-modal .modal-content {
  min-width: 520px;
}

.dashboard-customers .charts-main-box {
  padding: 23px;
  margin-bottom: 48px;
}

.chart-title {
  font-size: 18px;
  font-weight: 500;
}

.seo-audit .dashboard-sales-legend {
  justify-content: left;
}

.seo-audit .pagination a {
  text-decoration: none;
  /* background-color: #E0E0E0; */
}

.seo-audit .pagination a:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04);
}

.seo-audit .pagination p,
.seo-audit .pagination li,
.seo-audit .pagination a {
  font-size: 14px;
  padding: 4px 11px;
}

.seo-audit .col {
  display: block;
  float: left;
  margin: 1% 0 1% 1.6%;
}

.seo-audit .col:first-of-type {
  margin-left: 0;
}

/* style for first 3 */
.seo-audit .pagination .button-1 {
  background-color: #7fd8c9;
}

.seo-audit .pagination .button-2 {
  background-color: #fca69a;
}

.seo-audit .pagination .button-3 {
  background-color: #e4cb93;
}

/* ///////// */
.seo-audit .container {
  width: 100%;
  max-width: 940px;
  margin: 0 auto;
  position: relative;
  text-align: center;
}

.seo-audit .pagination {
  padding: 30px 0;
}

.seo-audit .pagination ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.seo-audit .pagination a {
  display: inline-block;
  border: 0;
  border-radius: 0px;
  padding: 0px;
  margin-right: 0px;
  color: #222;
}

.seo-audit .pagination.p12 ul.pagination {
  border: 0;
}

.seo-audit .pagination.p12 .pagination li {
  border-right: 0px solid #ddd;
  border: 1px solid #ddd;
  margin-right: 10px;
  border-radius: 5px;
  padding: 5px 12px;
}

.seo-audit .pagination.p12 .pagination li:last-child {
  border-right: 1px solid #ddd !important;
}

.seo-audit .p12 .prev,
.seo-audit .p12 .next,
.seo-audit .p12 .is-active {
  color: black;
}

.heading-customers span {
  font-size: 23px;
  margin-right: 24px;
}

.heading-customers i.fa.fa-star {
  color: goldenrod;
  margin-right: 8px;
}

span.progress-bar-customer {
  display: inline-block;
  width: 5%;
  height: 10%;
}

.progress-bar-sales {
  width: 23%;
}

.custom-row {
  width: 100%;
  display: flex;
}

.custom-column-33 {
  width: 35%;
  /* padding: 10px 16px; */
}

.custom-column-30 {
  width: 30%;
  padding: 10px 16px;
  border-left: 1px solid gray;
}

.justify-content-between .btn-primary {
  cursor: pointer;
}

.custom-column-70 {
  width: 100%;
}

.configuration select#clients,
.configuration select#clients:focus-visible,
.configuration select#client-proj,
.configuration select#client-proj:focus-visible {
  width: 100%;
  border: unset !important;
  border-bottom: 1px solid hsl(0, 0%, 80%) !important;
}

ul.configuration-tab-list {
  list-style: none;
  padding-left: 0;
}

td:first-child.ant-table-cell:hover div,
.accordian-table td:first-child.ant-table-cell div {
  color: #222 !important;
}


.accordian-table .fa.fa-trash {
  color: #2196f3;
}

ul.configuration-tab-list li:hover a,
td:first-child.ant-table-cell div {
  color: #0d6efd !important;
  cursor: pointer;
}

/* .configuration-tab-list li:first-child {
  margin-top: 15px;
} */

.configuration-disabled {
  opacity: 0.5;
}

.configuration-tab-list li:last-child {
  margin-bottom: 0px;
}

.configuration-tab-list li {
  cursor: pointer;
  font-size: 1.125rem;
  margin-bottom: 6px;
  color: #000000;
  margin-left: 14px;
}

.configuration-tab-list li a {
  font-size: 1.125rem;
  color: #000000 !important;
  font-weight: 400 !important;
}

.configuration .fa.fa-arrow-left {
  border: 1px solid gray;
  padding: 5px 10px;
  cursor: pointer;
  background-color: #0071ce;
  border-radius: 2px;
  margin-bottom: 30px;
  color: #fff;
}

h2.tab-title-comfiguration {
  font-size: 14px;
  margin-bottom: 12px;
  font-weight: 600;
  color: #333;
}

.configuration .SearchSelectMain .css-yk16xz-control,
.configuration .SearchSelectMain .css-1pahdxg-control {
  width: 100%;
  border: unset !important;
  border-bottom: 1px solid hsl(0, 0%, 80%) !important;
  box-shadow: unset;
}

.css-1pahdxg-control {
  box-shadow: unset !important;
  border: 1px solid hsl(0, 0%, 80%) !important;
}

.configuration .SearchSelectMain input:focus-visible {
  border: unset !important;
}

.SearchSelectMain.css-2b097c-container {
  text-transform: uppercase;
  font-size: 15px;
}

.audit-modal .modal-dialog {
  max-width: 66%;
  margin-left: auto;
  margin-right: unset;
  margin-top: unset;
  height: inherit;
}

.audit-modal .add-user {
  width: 100% !important;
}

.view-audit-config {
  color: rgb(13, 110, 253) !important;
}

.page-speed-main .da-pa-search {
  margin-right: 24px;
  width: 68%;
}

.wrapper.dashboard-seo-dropdown {
  display: inline-flex;
  flex-direction: column;
}

.dashboard-seo-dropdown .button {
  /* border: 1px solid #2185d0; */
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 400;
  background-color: white;
  width: 140px;
}

.dashboard-seo-dropdown .list {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  border: 1px solid #ccc;
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: auto;
  display: inline-block;
}

.dashboard-seo-dropdown .list-item {
  padding: 8px;
  cursor: pointer;
  background-color: white;
}

.dashboard-seo-dropdown .list-item:hover,
.dashboard-seo-dropdown .list-item:active {
  background-color: #f3f3f3;
}

.logo {
  width: 62%;
}

.nav-bar-center {
  width: 0%;
}

.dashboard-seo-dropdown .row {
  background-color: #fff;
  border: 1px solid rgba(127, 127, 127, 0.15);
  border-radius: 0.25rem;
  width: 450px !important;
}

.dashboard-seo-dropdown .col-md-6.project-list {
  padding: 13px 8px;
}

.dashboard-seo-dropdown .col-md-6.view-list {
  padding: 13px 8px;
}

.dashboard-seo-dropdown ul {
  list-style: none;
  padding-left: unset;
}

.dashboard-seo-dropdown ul li {
  padding: 0;
  cursor: pointer;
  font-size: 12px;
  margin: 8px 0;
  border-radius: 0px;
}

.dashboard-seo-dropdown ul li:first-child {
  margin: 0;
}

.dashboard-seo-dropdown ul li:last-child {
  margin: 0;
}

.dashboard-seo-dropdown li span {
  width: 100%;
  display: block;
  padding: 8px 15px;
  margin: 0;
}

.dashboard-seo-dropdown i.fa.fa-angle-right {
  margin-left: 36%;
}

.dashboard-seo-dropdown .Clients-list span {
  display: inline-block;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  width: 50%;
}

.dashboard-seo-dropdown .Clients-list li:hover,
.dashboard-seo-dropdown li:hover span {
  color: #42a5f4 !important;
  background: #f5f5f5 !important;
}

.dashboard-seo-dropdown .Clients-list li:active,
.dashboard-seo-dropdown li:active span {
  background: #2196f3 !important;
  background: #2196f3 !important;
  z-index: 2;
  color: #212121 !important;
}

/* divya */
.dashboard-seo-dropdown i.fa.fa-angle-right {
  margin-left: 36%;
}

.dashboard-seo-dropdown .campaign-List span {
  display: inline-block;
  color: #212121;
  font-size: 1rem;
  font-weight: 500;
  width: 50%;
}

.dashboard-seo-dropdown .campaign-viewList li span {
  width: 100%;
  display: block;
  padding: 8px 15px;
  margin: 0;
  color: #212121;
  font-weight: 400;
  font-size: 0.875rem;
  /* line-height: 21px; */
}

/* .dashboard-seo-dropdown .campaign-List li:hover,
.dashboard-seo-dropdown li:hover span {
  color: #42a5f4 !important;
  background: #f5f5f5 !important;
} */

.dashboard-seo-dropdown .campaign-List li:active,
.dashboard-seo-dropdown .campaign-List li:active span,
.dashboard-seo-dropdown .campaign-viewList li:active span {
  background: #E2E8F0 !important;
  z-index: 2;
  color: #212121 !important;
}

.dashboard-seo-dropdown .campaign-List li:hover,
.dashboard-seo-dropdown .campaign-List li:hover span,
.dashboard-seo-dropdown .campaign-viewList li:hover span {
  background: #E2E8F0 !important;
  z-index: 2;
  color: #212121 !important;
}

.custom-row-dashboard-seo {
  width: 100%;
  display: flex;
}

.custom-column-20-dashboard-seo {
  width: 11%;
}

.custom-column-80-dashboard-seo {
  width: 88%;
  margin-top: 104px;
}

.custom-column-10-dashboard-seo {
  width: 3%;
}

.custom-column-90-dashboard-seo {
  width: 96%;
  margin-top: 104px;
}

.custom-column-20-dashboard-seo .sidebar-nav-bar {
  /* width: 10%; */
  position: fixed;
}

.configuration #user-sub .sidebar-nav-bar,
.configuration #user-sub .custom-column-20-dashboard-seo {
  display: none;
}

.configuration #user-sub .Common-mt-64 {
  margin-top: 0px;
  width: 100%;
}

.custom-column-20-dashboard-seo .sidebar-nav-bar a {
  display: flex;
}

.custom-column-10-dashboard-seo .sidebar-nav-bar {
  width: 46px;
  position: fixed;
}

.Common-mt-64 {
  margin-top: 114px;
  margin-left: 24px;
  width: 83%;
  position: relative;
}

.Common-Row-Div {
  display: flex;
  justify-content: left;
  overflow: hidden;
  gap: 40px;
}

.Image-Dashboard {
  width: 100%;
}

.custom-column-10-dashboard-seo .sidebar-nav-bar a {
  padding-left: 9px !important;
}

.custom-column-20-dashboard-seo .sidebar-nav-bar i {
  width: 31px;
}

.dashboard-seo .line-graph svg {
  max-width: 87%;
}

.custom-column-20-dashboard-seo button.control-toggle-dashboard-seo {
  border-radius: 50%;
  height: 35px;
  width: 35px;
  /* float: right; */
  font-size: 20px;
  margin-right: 5px;
  margin-left: 5px;
}

.custom-column-10-dashboard-seo button.control-toggle-dashboard-seo {
  border-radius: 50%;
  height: 25px;
  width: 25px;
  float: right;
  font-size: 15px;
  margin-right: 5px;
  margin-left: 5px;
}

.ticket-details-dashboard h6 {
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  height: 32px;
}

.ticket-details-dashboard p {
  text-align: center;
  font-size: 24px;
  margin-bottom: unset;
}

.ticket-details-dashboard {
  margin-bottom: 24px;
  box-shadow: 7px 20px 24px #bebebe, -47px -20px 60px #ffffff;
  padding: 10px 0px;
  border: 1px solid #bebebe;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

ul.audit-titles li {
  font-size: 15px;
}

ul.audit-titles {
  margin-top: 12px;
  /* padding-left: 11px; */
  list-style-type: disc;
}

.margin-left-seo-audit-5 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.charts-flex {
  display: flex;
}

.SideMob {
  display: none;
}

.prev-nex i {
  color: white !important;
  font-size: 29px;
}

.rank-track-graph .css-yk16xz-control {
  width: 15em;
}

.prev-nex button.btn-primary {
  margin-left: 5px;
  margin-right: 5px;
  padding: 3px 14px;
  border-radius: 6px;
}

img.filterimage {
  width: auto;
}

.roi-small,
.roi-big {
  width: 40%;
  padding: 20px 0;
  height: max-content;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background: #ffcb35;
  border-radius: 5px;
}

.roi-big {
  width: 50%;
}

.roi-small h4,
.roi-big h4 {
  margin-bottom: 0;
}

.page-speed-avg .da-average-div {
  text-align: center;
  opacity: 0.8;
  background-color: #ffcb35;
  color: #000;
  width: 60%;
  padding: 90px 30px;
  border-radius: 4px;
  border: 1px solid #ffcb35;
  margin-left: auto;
  margin-right: auto;
  height: 60%;
}

.da-average-div h6,
.da-average-div p {
  font-size: 12px;
  font-weight: 600;
}

.rmdp-shadow.custom-calendar {
  border: 1px solid #838282;
  border-radius: 0.4rem;
  box-shadow: none;
  display: inline-block;
}

.rmdp-shadow.custom-calendar .rmdp-calendar {
  height: 290px;
}

.rmdp-shadow.custom-calendar .rmdp-header {
  height: 40px;
  margin-top: 0px;
}

.rmdp-calendar {
  height: max-content;
  padding: 20px;
  /* border: 1px solid #cfd8e2; */
  /* margin: 10px 10px; */
}

.rmdp-border-right {
  border: 1px solid #cfd8e2;
}

.rmdp-panel {
  min-width: 110px;
}

.rmdp-shadow {
  box-shadow: -1px 8px 49px -10px rgb(174 174 174 / 75%);
}

.daterange-picker-card .card-body {
  box-shadow: -1px 8px 49px -10px rgba(174, 174, 174, 0.75);
  -webkit-box-shadow: -1px 8px 49px -10px rgba(174, 174, 174, 0.75);
  -moz-box-shadow: -1px 8px 49px -10px rgba(174, 174, 174, 0.75);
}

.custom-calendar select {
  width: 135px;
}

.custom-calendar input {
  width: 135px;
}

.rmdp-panel-body li .b-deselect {
  height: 14px;
  width: 14px;
  /* background-color: hsl(0deg 0% 34%); */
  color: #222;
}

.rmdp-panel-body li {
  background-color: #2196f3;
}

.rmdp-wrapper {
  border-radius: 0;
  margin-right: 10px;
}

.custom-calendar input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  margin-left: -70px;
}

.custom-calendar .daterange-picker-card {
  font-size: 12px;
  right: 0px;
  /* position:absolute; */
  z-index: 4;
  float: right;
}


.custom-calendar .rmdp-week-day {
  color: #000;
  cursor: pointer;
  height: 24px;
  position: relative;
  width: 24px;
  /* width:20%; */
}

/* .ep-arrow::after */
.custom-calendar .rmdp-day {
  color: #000;
  cursor: pointer;
  height: 2.5rem;
  position: relative;
  width: 2.5rem;
  box-shadow: inherit;
  border-radius: 0.40rem;
}

.custom-calendar .rmdp-range.end,
.custom-calendar .rmdp-range.start {
  border-radius: 0.40rem;
}


.rmdp-calendar .rmdp-arrow-container {
  border-radius: 0;
}

.rmdp-calendar .rmdp-arrow-container:hover {
  background-color: #f5f5f5;
  box-shadow: none;
  border-radius: 0.25rem;

}

.rmdp-calendar .rmdp-arrow {
  border: solid #333;
  border-width: 0 2px 2px 0;
}

.rmdp-calendar .rmdp-arrow-container:hover .rmdp-arrow {
  border: solid #0d6efd;
  border-width: 0 2px 2px 0;
}

.rmdp-calendar .rmdp-header-values {
  padding: 0 10px;
  border-radius: 0.25rem;
  /* height: 2.5rem; */
  width: 12.5rem
}

.rmdp-calendar .rmdp-arrow {
  margin-top: 14px;
}

.rmdp-calendar .rmdp-arrow-container {
  height: 36px;
  width: 36px;
  top: 54%;
}

.ga-calender .rmdp-header-values span {
  font-size: 12px;
}

.custom-calendar .rmdp-day span {
  border-radius: 0.40rem;
  bottom: 0px;
  font-size: 10px;
  left: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
}

.custom-calendar .rmdp-week-day {
  color: #222;
  cursor: default;
  font-size: 10px;
  font-weight: 600;
}

.custom-calendar .rmdp-header {
  font-size: 13px;
  height: 38px;
  line-height: 37px;
  margin-top: 5px;
}

.custom-calendar .lable-compare {
  margin-left: -55px;
}

div#example-collapse-text-calendar {
  /* max-width: 969px; */
  width: fit-content;
  margin-left: auto;
  position: absolute;
  z-index: 9999;
  right: 0;
  /* top: 23%; */
}

.ga-calender div#example-collapse-text-calendar {
  top: 100%;
}

.tabs-inner-page-speed .react-tabs__tab-panel {
  margin-top: unset;
}

/* #example-collapse-text-calendar input[type="text"], */
#example-collapse-text-calendar select {
  /* width: 129% !important; */
  max-width: 129% !important;
  text-transform: capitalize;
}

#example-collapse-text-calendar input[type="text"] {
  max-width: 129% !important;
}

#example-collapse-text-calendar label {
  width: auto;
}

.ROI .ant-table-selection-column label {
  width: unset;
}

.calendar-main {
  margin-top: 0px;
}

.col-lg-2.box-tickets-num {
  color: white;
  padding: 10px;
  border-radius: 4px;
  width: 17% !important;
  margin-right: 10px;
  cursor: pointer;
}

.ticket-details-dashboard .row {
  width: 99%;
  margin-left: auto;
}

.team-members-hr .control-toggle-dashboard-seo {
  margin-top: 73vh;
}

.sidebar-sales .control-toggle-dashboard-seo {
  margin-top: 70vh;
}

.kr-list .custom-column-80 {
  width: 80%;
}

.kr-list .custom-column-20 {
  width: 20%;
  text-align: end;
}

.kr-list-inner {
  padding: 13px 32px;
  border-bottom: 1px solid lightgray;
}

.modal-KR .modal-body {
  padding: 1rem 0rem !important;
}

.kr-list-inner ul {
  list-style-type: none;
}

.kr-list-inner li {
  padding: 6px 0px;
}

.kr-list-inner input[type="checkbox"] {
  margin-right: 5px;
}

.kr-list-inner p {
  font-size: 14px;
  margin-left: 10px;
  margin-top: 5px;
  color: gray;
  letter-spacing: 0.5px;
}

@media (max-width: 1440px) and (min-width: 1025px) {
  .keyowrd-ROI-drop {
    flex-direction: column;
  }

  .keyowrd-ROI-drop label {
    margin-top: unset !important;
  }

  #atmospheric-composition {
    margin-left: -83px !important;
    margin-top: -103px;
  }

  .seo-volatality .ant-table-cell,
  .seo-volatality .text-primary {
    font-size: 12px;
  }

  .table.sevolatality-border td {
    padding: 8px 0px !important;
  }

  .da-pa-search {
    width: 33% !important;
  }

  .view-client span.slider.round {
    width: 91%;
    margin-left: 12%;
  }

  .keyowrd-ROI-drop label {
    margin-top: unset !important;
  }

  #atmospheric-composition {
    margin-left: -83px !important;
    margin-top: -103px;
  }

  /* .view-client span.slider.round {
    width: 91%;
    margin-left: 12%;
  } */

  .ROI input[type="text"],
  .ROI select {
    max-width: 96%;
    height: 40px;
    width: 100%;
  }

  .ROI .da-pa-search.css-2b097c-container {
    max-width: 96% !important;
    width: 100% !important;
  }

  .ROI .row label {
    width: 68%;
    margin-right: 24px;
  }

  .module-expand-gsc label {
    width: 31%;
    margin-right: 24px;
  }

  label.aggregation-type {
    width: 34%;
    margin-right: 12px;
  }

  .module-expand-click-share label {
    width: 47%;
    margin-right: 0px;
    margin-top: 6px;
  }

  .module-expand-click-share input[type="text"],
  .module-expand-click-share input[type="file"],
  .module-expand-click-share select {
    width: 47%;
  }

  .module-expand-click-share .Click-Share-Keyword {
    width: 52%;
  }

  .website-main input[type="date"] {
    width: 32%;
    margin-right: 24px;
  }

  .website-main label {
    width: 20% !important;
  }

  .module-rank .device-type-rank-choose {
    max-width: 54%;
  }

  .rank-tracking-top-select .da-pa-search.css-2b097c-container {
    max-width: 65%;
  }

  .rank-tracking-top-select label {
    width: 40%;
  }
}

@media (max-width: 1024px) {

  .module-expand-click-share .col-lg-3,
  .module-expand-click-share .col-lg-4,
  .module-expand-click-share .col-lg-2 {
    width: 50%;
    margin-bottom: 24px;
  }

  .seo-volatality .ant-table-cell,
  .seo-volatality .text-primary {
    font-size: 12px;
  }

  .table.sevolatality-border td {
    padding: 8px 0px !important;
  }

  .view-client .form-wrappers label {
    width: 100%;
  }

  .view-client input[type="date"],
  .view-client input[type="text"],
  .view-client select {
    width: 100%;
  }

  #sample .css-1pahdxg-control,
  #sample .css-yk16xz-control {
    width: 38.5em;
  }

  .common-table {
    width: -webkit-fill-available;
  }

  .data-export {
    margin-left: 0px;
  }

  .data-export.upload-credental {
    margin-left: 0;
  }

  .data-export-client {
    text-align: end;
    /* margin-left: -8em; */
  }

  .ROI input[type="text"],
  .ROI select {
    width: 61%;
    height: 40px;
  }

  .module-expand-click-share #sample-module-expand {
    overflow-x: scroll;
  }

  .module-expand-click-share label {
    width: 42%;
  }

  .module-expand-click-share input[type="text"],
  .module-expand-click-share input[type="file"],
  .module-expand-click-share select {
    width: 56%;
  }

  .module-expand-click-share .Click-Share-Keyword {
    width: 56%;
  }
}

@media (min-width: 1024px) and (max-width: 1250px) {
  .click-share-charts .line-graph {
    margin-left: -19%;
  }

  .seo-volatality .ant-table-cell,
  .seo-volatality .text-primary {
    font-size: 12px;
  }

  .table.sevolatality-border td {
    padding: 8px 0px !important;
  }

  .module-expand-click-share {
    overflow-x: hidden;
  }

  .module-expand-click-share #sample-module-expand {
    overflow-x: scroll;
  }

  .module-expand-click-share .col-lg-3,
  .module-expand-click-share .col-lg-4,
  .module-expand-click-share .col-lg-2 {
    width: 50%;
    margin-bottom: 24px;
  }
}

.nav-right-menu .dropdown-menu a:hover {
  display: block;
  padding: unset;
  /* clear: both; */
  font-weight: unset;
  background-color: unset;
  line-height: inherit;
  color: #abacc3;
  white-space: nowrap;
}

.seo-audit .CircularProgressbar {
  width: 40%;
  text-shadow: #262626;
  vertical-align: middle;
}

.multiselect-graph-ga .css-yk16xz-control {
  height: 40px;
  overflow-y: scroll;
}

.multiselect-graph-ga .css-yk16xz-control .css-1hb7zxy-IndicatorsContainer {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .seo-audit .CircularProgressbar {
    width: 80%;
    text-shadow: #262626;
    vertical-align: middle;
  }
}

.audit-card {
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 4px;
  text-transform: capitalize;
  margin-left: auto;
  margin-right: auto;
  font-weight: 700;
}

.seo-audit .pop-over .form-wrappers {
  padding: 0 0px 16px 0;
}

.seo-audit .pop-over {
  position: absolute;
  top: 35%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  /* position:relative; */
  /* display: none; */
  /* margin-left: auto; */
  /* margin-right: auto; */
  width: 50%;
  height: min-content;
  padding-bottom: 30px;
  background-color: #ffffff;
  border: #212529;
  /* overflow-y: auto; */
  border-radius: 8px;
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
  z-index: 3;
}

@media only screen and (max-width: 1012px) {
  .seo-audit .pop-over {
    position: fixed;
    margin-left: auto;
    width: 85%;
    height: 85%;
    background-color: #ffffff;
    border: #212529;
    /* overflow-y: auto; */
    border-radius: 8px;
    box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
    z-index: 3;
  }

  .seo-audit .pop-over .header {
    margin-left: auto;
    margin-right: auto;
    width: 200%;
  }

  .seo-audit .pagination li {
    font-size: 14px;
    padding: 1px 5px;
  }
}

.seo-audit .form-scroll {
  /* display: none; */
  width: 100%;
  height: 70%;
  overflow: auto;
}

.seo-audit .add-user {
  width: 100%;
}

.seo-audit .pop-over .header {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.seo-audit .pagination2 {
  margin-left: 60%;
  padding: 30px 0;
}

.seo-audit .progress-circle svg {
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  font-size: 10px;
  filter: drop-shadow(9px 3px 9px grey);
}

.seo-audit .CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  dominant-baseline: middle;
  text-anchor: middle;
}

.seo-audit .progress-circle {
  /* margin-left: 90px; */
  min-height: 300px;
  min-width: 400px;
  border-radius: 8px;
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
}

.seo-audit .progress-text {
  min-height: 300px;
  min-width: 400px;
  /* margin-top: auto; */
  /* margin-bottom: auto; */
  border-radius: 8px;
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
}

.seo-audit .audit-button {
  width: 106px;
}

.seo-audit .audit-title {
  font-size: 20px;
  font-weight: 500;
}

.seo-audit .pop-over-footer a {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 7px;
  padding: 9px;
  margin-right: 8px;
  color: black;
  font-size: 14px;
}

.seo-audit .pop-over-footer {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

.config-dashboard .table-normal {
  border: 1px solid black;
  border-radius: 5px;
  /* border-width: 2px; */
}

.config-dashboard .table-norma {
  padding: 10px;
}

.config-dashboard .display {
  height: 50%;
  width: 30%;
  margin-top: auto;
  margin-bottom: auto;
}

table.table-modules {
  width: 100%;
  height: 100%;
  text-align: left;
  border-radius: 2px 2px 0 0;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: auto;
}

.seo-volatality .ant-table-cell,
.seo-volatality .text-primary {
  font-size: 12px;
}

.seo-volatality .text-success {
  font-size: 12px;
}

/* .table.sevolatality-border td {
  padding: 8px 0px !important;
} */

.table-modules tbody,
.table-modules td,
.table-modules tfoot,
.table-modules th,
.table-modules thead,
.table-modules tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

.dapa-table.table-modules tbody{
  height: 23vh;
}
.table-modules {
  border-top: 2px solid #2b2b2b;
  border-bottom: 2px solid #2b2b2b;
}

.table-modules thead>tr .left-right-border {
  border-left: 1px solid #2b2b2b;
}

.table-modules th {
  position: relative;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: center;
  background: #fafafa;
  border-bottom: 2px solid #2b2b2b;
  transition: background 0.3s ease;
}

.table-modules thead>tr>th,
.table-modules tbody>tr>td,
.table-modules tfoot>tr>th,
.table-modules tfoot>tr>td {
  position: relative;
  padding: 16px 66px;
  border-left: 2px solid #2b2b2b;
  border-right: 2px solid #2b2b2b;
  overflow-wrap: break-word;
}

.table-modules thead>tr>th:not(:last-child)::before {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 1.6em;
  background-color: rgba(0, 0, 0, 0.06);
  transform: translateY(-50%);
  transition: background-color 0.3s;
  content: "";
}

.table-modules tbody>tr>td {
  border-bottom: 1px solid #f0f0f0;
  /* border-left: 1px solid #2B2B2B;
	border-right: 1px solid #2B2B2B; */
  transition: background 0.3s;
}

.table-modules .expand-button {
  margin-left: 2%;
  border: unset;
  background-color: unset;
}

.table-modules .add-user {
  width: 100%;
}

.table-modules .css-2b097c-container {
  width: 25em;
  /* width: 48%; */
  /* margin-bottom: 24px; */
}

.table-modules ul.input-list li:before {
  content: "✓";
  color: #027d02;
  font-size: 19px;
  margin-right: 10px;
}

ul.input-list {
  list-style: none;
  padding-left: unset;
  font-size: 17px;
}

.table-modules .css-2613qy-menu {
  font-weight: 100 !important;
}

.table-modules select#keywordGTFrequency,
select#UrlPSFrequency {
  width: 25em;
}

.table-modules .none td {
  padding: inherit;
}

.table-modules .trash-module {
  color: #0d6efd;
}

.layout {
  background-color: #f5f5f5;
  border-radius: 10px;
}

.layout-col {
  padding: 0;
  text-align: center;
  /* background-color: rgba(33, 150, 243, 0.4) !important; */
  background-color: #bcdefa !important;
  border-radius: 5px;
  height: 60px;
  width: 60px;
  line-height: 60px;
}

.configuration-tab-list li a span {
  float: right;
}

.date-modal .modal-dialog {
  max-width: 980px;
}

.date-modal .calendar-main {
  margin-top: unset;
}

.date-modal .add-new-btnw {
  text-align: center;
}

.date-modal div#example-collapse-text-calendar {
  max-width: 969px;
  width: 100%;
  margin-left: auto;
  z-index: 3;
  position: relative;
  right: unset;
  margin-right: auto;
}

.overall-ga {
  font-size: 32px;
}

.tab-vertical {
  display: flex;
}

.tab-vertical .react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  width: 100px;
  margin: 0;
  padding: 0;
  border-right: 1px solid black;
  border-bottom: unset;
}

.tab-vertical .react-tabs__tab {
  /* height: 70px; */
  list-style: none;
  padding: 12px 6px;
  cursor: pointer;
  color: #000;
}

.tab-vertical .react-tabs__tab--selected {
  border: unset;
  font-weight: 600;
  color: #fff;
  background-color: #4e73df;
  background-image: linear-gradient(180deg, #4e73df 10%, #224abe 100%);
  background-size: cover;
  border-radius: unset;
}

.tab-vertical .react-tabs__tab-panel {
  display: none;
  width: 300px;
}

.tab-vertical .react-tabs__tab-panel--selected {
  display: block;
  margin-left: 12px;
}

.tab-vertical .react-tabs__tab {
  padding-left: 21px;
}

.tab-vertical .react-tabs__tab--selected {
  padding-left: 21px;
}

.tab-vertical .panel-content {
  text-align: center;
}

.step-status {
  display: flex;
  justify-content: center;
  line-height: 16px;
  align-items: center;
  text-align: center;
}

.RSPBprogressBar {
  height: 6px !important;
}

.progress {
  justify-content: space-between;
  height: max-content !important;
  position: relative;
  overflow: inherit;
}

.step-status .clients {
  display: flex;
  justify-content: center;
  font-size: 13px;
  line-height: 8px;
  align-items: center;
  text-align: center;
}

.progress-bar-lead-add .RSPBstep {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  height: 6px;
  transform: translateX(-50%);
  transition-property: all;
  transition-timing-function: ease;
}

.progress-bar-lead-add .progress {
  background-color: rgba(211, 211, 211, 0.6);
}

.common-label {
  font-size: 14px;
}

.custom-gogle-trends .css-yk16xz-control {
  min-height: 34px;
  font-size: 14px;
  height: 20px;
}

.page-speed-main #select-url-page-speed {
  height: 30px;
  font-size: 14px;
  padding: 4px 14px;
}

.website-main .css-yk16xz-control {
  min-height: 34px;
  height: 20px;
  font-size: 14px;
}

.module-rank .css-yk16xz-control {
  min-height: 34px;
  height: 20px !important;
  font-size: 14px;
  padding: 0px 12px;
}

.module-expand-site-uptime .css-yk16xz-control {
  min-height: 34px;
  height: 20px !important;
  font-size: 14px;
}

.module-expand-ga .css-yk16xz-control {
  min-height: 32px;
  height: 20px;
  font-size: 14px;
}

.field-required {
  color: red !important;
}

#Click-share .css-2b097c-container,
#rank-tracking .css-2b097c-container,
#SUM .css-2b097c-container {
  display: inline-block;
  width: 80%;
}

#SUM .css-1wa3eu0-placeholder {
  position: relative;
  -webkit-transform: none;
  transform: inherit;
}

#SUM .css-g1d714-ValueContainer {
  padding: 0;
}

#gt .modules-config select,
#page-speed .modules-config select,
select#UrlPSFrequency,
.modules-config .css-2b097c-container {
  width: 100%;
}

#Click-share .m3 {
  width: fit-content;
}

#Click-share .table {
  width: 60%;
  text-align: center;
}

#Click-share .table.table-bordered thead th {
  border-bottom: 0px solid #000;
}

#Click-share .table> :not(:first-child) {
  border-top: 0px solid #000;
}

#Click-share .table.table-bordered thead {
  background: #ffcd3e;
}

#Click-share .table.table-bordered tbody td input {
  border-color: black;
}

.flex-width {
  width: 80%;
  margin-top: 15px;
}

.loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #ffffff78;
  z-index: 99999;
}

.spinner-border {
  display: inline-block;
  width: 5rem !important;
  height: 5rem !important;
  vertical-align: -0.125em;
  /* color: #4e73df !important; */
  /* color: #ffcb35 !important; */
  color: #757575 !important;
  border-width: 0.5em !important;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.export-bttn a {
  border: 0px solid #4e73df;
}

.export-bttn .dropdown-menu.show {
  padding: 10px 15px;
}

.profile-img {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  border: 1px solid #ddd;
  position: absolute;
  top: 0;
  left: 0;
  /* padding: 10px; */
}

.profile-box .file-btn {
  position: absolute;
  bottom: 10px;
  left: 90px;
}

.profile-box {
  text-align: center;
  border: 1px solid #ddd;
  height: 120px;
  width: 120px;
  border-radius: 70px;
}

.img-edit {
  position: absolute;
  right: 0px;
  top: 0px;
  background: #f6f1f1;
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 100%;
}

.img-edit img {
  width: 18px;
}

#customize .table-modules-head .table-modules thead>tr>th,
#customize .table-modules tbody>tr>td,
#customize .table-modules tfoot>tr>th,
#customize .table-modules tfoot>tr>td {
  padding: 15px 10px;
}

#BLC .css-2b097c-container {
  width: 80%;
}

select#type {
  width: 80%;
}

select#ListFrequency {
  width: 80%;
}

iframe {
  pointer-events: none;
}

#DAPAC .css-2b097c-container {
  width: 80%;
}

#CWC .css-2b097c-container {
  width: 80%;
}

#OR .css-2b097c-container {
  width: 80%;
}

.content-box {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.content-box .btn {
  background: #ddd;
  background-color: #ffcb35;
}

.deactive {
  pointer-events: none;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; */
  font-family: 'Montserrat', sans-serif;
}

.login {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.button {
  background-color: #61dafb;
  border: 1px solid #61dafb;
  color: white;
  padding: 12px 24px;
  font-size: 16px;
  text-decoration: none;
}

.home {
  display: flex;
  height: 100vh;
}

.sidebar {
  min-width: 300px;
  background: #f2f5fa;
  height: 100%;
  box-sizing: border-box;
  padding: 18px;
  font-size: 14px;
}

.sidebar h2 {
  padding-left: 12px;
}

.sidebar ul {
  list-style: none;
  padding-left: 0;
}

.sidebar li {
  padding: 12px;
  border-bottom: 1px solid #e2e2e2;
  transition: all 0.2s ease;
}

.sidebar li:hover {
  background: #e2e9f5;
}

.sidebar li a {
  color: black;
  text-decoration: none;
}

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.navbar {
  border-bottom: 2px solid #f2f5fa;
  width: 100%;
  max-height: 60px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 18px;
}

.navbar ul {
  list-style-type: none;
  display: flex;
  justify-content: end;
}

.navbar li a {
  text-decoration: none;
  color: black;
  padding: 18px 12px;
  margin: 0 4px;
}

.navbar li a:hover {
  background: #e2e9f5;
}

.page-main {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.drop-hover .dropdown-content {
  color: #7e7e7e;
}

.table-box a:not([href]):not([tabindex]):hover {
  color: #2196f3;
}

.table-box a:not([href]):not([tabindex]):active {
  color: #fff;
}

.btn-primary {
  background-color: #0691EF;
  border-radius: 0.40rem;
  width: auto;
}

.btn-primary.dropdown-toggle:hover {
  color: #fff !important;
  background: #2196f3 !important;
}

.portlet-footer .btn-primary {
  color: #fff;
  background: #2196f3;
  border-color: #2196f3;
  border-radius: 0.40rem;
  width: 70px;
  height: 30px;
}

#Click-share .css-b62m3t-container,
#rank-tracking .css-b62m3t-container,
#blc .css-b62m3t-container,
#or .css-b62m3t-container,
#dapac .css-b62m3t-container {
  width: -webkit-fill-available;
}

.images-description .file-btn {
  background: transparent;
  border: 1px solid #42a5f4;
}

.calendar-main .add-new-btnw .outline-btn-boderless {
  height: 45px;
  border: 1px solid #ddd;
  background: #f5f5f5;
}

.config-dashboard td button {
  border: 1px solid #d1d1d1;
}

input[type="date"].device-date {
  background: transparent;
}

.add-new-btnw .ant-input-group-wrapper.ant-input-search .ant-input-affix-wrapper,
.add-new-btnw .ant-input-group-wrapper.ant-input-search .ant-input-affix-wrapper input[type="text"],
.add-new-btnw .ant-input-group-wrapper.ant-input-search .ant-input-affix-wrapper .ant-input-group-addon,
.add-new-btnw .ant-input-group-wrapper.ant-input-search .ant-input-affix-wrapper .ant-btn,
.add-new-btnw .ant-input-group-wrapper.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child,
.button.ant-btn.ant-btn-icon-only.ant-input-search-button {
  background-color: transparent;
}

.file-btn {
  border: 1px solid #8dc7f4;
  background: transparent;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background: #42a5f4;
}

.file-btn i {
  color: #fff;
  line-height: 30px;
}

.sea-100 input {
  width: 100%;
}

.react-confirm-alert-body button {
  background: #42a5f4;
}


.react-confirm-alert-body h1 {
  font-size: 1.5rem !important;
}

.accordian-table .ant-table-tbody>tr>td,
.accordian-table ul.audit-titles {
  padding: 0px;
  margin: 0;
}

.accordian-table .ant-table-tbody td .accordion-item {
  padding: 15px;
}

.accordian-table .ant-table-tbody td ul {
  padding: 8px;
}


.accordian-table .ant-table-tbody td .accordion-item {
  background: #B9DCFA;
  color: #fff;
}

.accordian-table .ant-table-tbody td .accordion-item.collapsed {
  background: transparent;
  color: #222;
}

/* .search-table-outter th,
td,
.search-table-outter .ant-table-container table>thead>tr:first-child th:first-child {
  min-width: 50px;
} */

.paid-table .ant-table-container table>thead>tr:first-child th:first-child {
  width: 8%;
}

.dash-table.table-modules.table.table-bordered tbody>tr>td,
.dash-table.table-modules.table.table-bordered th {
  /* width: 0; */
  /* min-width: 0; */
  padding: 10px 6px;
}

.contentword-table.dash-table th:nth-child(1),
.contentword-table.dash-table th:nth-child(2),
.contentword-table.dash-table th:nth-child(3) {
  min-width: 340px;
}

/* .search-table-outter th:nth-child(2) td:nth-child(2) {
  min-width: 200px;
} */

/* td:nth-child(2) {
  min-width: 250px;
} */

.table.client-table th:last-child {
  min-width: 100px;
}

.client-table .ant-table-container table>thead>tr:first-child th:last-child {
  width: 100px;
}

.table.client-table td:nth-last-child(3) {
  width: 120px;
}

.table.client-table td:nth-last-child(3) {
  width: 120px;
}

.table.client-table th:nth-child(2) {
  width: 185px;
}

.table.client-table th:nth-child(6) {
  width: 220px;
}

.table.client-table th:nth-child(4) {
  width: 145px;
}

table.client-table th:nth-child(5) {
  width: 120px;
}


/* .table.employee-table th:nth-child(3) {
  width: 15%;
} */

.table.employee-table td:nth-child(9),
.table.employee-table td:nth-child(10) {
  min-width: 150px;
}

.table.employee-table td:nth-child(3) {
  min-width: 135px;
}


.table.employee-table td:nth-child(6) {
  min-width: 255px;
}

.table.employee-table td:nth-child(5) {
  min-width: 180px;
}

.table.employee-table td:last-child {
  min-width: 100px;
}

.table.employee-table th:nth-child(8) .ant-table-filter-column {
  min-width: 115px;
}

.table.employee-table td:nth-child(4) {
  min-width: 130px;
}

.table.employee-table td:nth-child(11) {
  min-width: 200px;
}

.table.employee-table td:nth-child(13) {
  min-width: 185px;
}

.table.employee-table td:nth-child(2) {
  min-width: 140px;
}

.table.project-table td:last-child {
  min-width: 160px;
}

.table.project-table td:nth-child(3),
.table.project-table td:nth-child(7),
.table.project-table td:nth-child(6) {
  min-width: 110px;
}

.table.project-table td:nth-child(5) {
  min-width: 105px;
}


.table.ticket-table td:nth-child(8) {}

.table.ticket-table td:nth-child(3) {
  min-width: 175px;
}

.table.ticket-table td:nth-child(7),
.table.ticket-table td:nth-child(9) {
  min-width: 106px;
}

.table.ticket-table td:nth-child(2) {
  min-width: 100px;
}


.table.project-table td:first-child {
  min-width: 10px;
}

.table.country-table td:nth-child(2) {
  min-width: 270px;
}

.table.country-table td:nth-child(3),
.table.country-table td:nth-child(4) {
  min-width: 250px;
}

.table.city-table td:last-child {
  min-width: 110px;
}

/* .table.ranktracking-table td:nth-child(6) {
  min-width: 195px;
} */


/* .table.ranktracking-table td:nth-child(9) {
  min-width: 320px;
} */



/* .table.ranktracking-table td:nth-child(11) {
  min-width: 500px;
} */


.table.ranktracking-table1 td:nth-child(2) {
  min-width: 200px !important;
}

.table.ranktracking-table1 td:nth-child(4) {
  min-width: 100px !important;
}

.table.ranktracking-table1 td:nth-child(6) {
  min-width: 90px !important;
}

.table.ranktracking-table1 td:nth-last-child(odd) {
  min-width: 320px;
}

.ranktracking-table1 .ant-table-content,
.gsc-table .ant-table-content,
.googleanalytics-table .ant-table-content {
  overflow-y: auto;
  height: 400px;
}

.ranktracking-table1.d-tracking .ant-table-content {
  height: max-content;
}

.ranktracking-table1 .ant-table-content .ant-table-thead,
.gsc-table .ant-table-content .ant-table-thead,
.googleanalytics-table .ant-table-content .ant-table-thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.ant-table-content .ant-table-thead {
  text-transform: capitalize;
}

.ranktracking-table .ant-table-content,
.gsc-table .ant-table-content,
.googleanalytics-table .ant-table-content {
  overflow-y: auto;
  height: 400px;
}

.ranktracking-table.d-tracking .ant-table-content {
  height: max-content;
}

.ranktracking-table .ant-table-content .ant-table-thead,
.gsc-table .ant-table-content .ant-table-thead,
.googleanalytics-table .ant-table-content .ant-table-thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

/* 
.table.ranktracking-table td:nth-child(17) {
  min-width: 350px;
}

.table.ranktracking-table td:nth-child(19) {
  min-width: 400px;
}

.table.ranktracking-table td:nth-child(21) {
  min-width: 500px;
}

.table.ranktracking-table td:nth-child(23) {
  min-width: 400px;
}

.table.ranktracking-table td:nth-child(27) {
  min-width: 400px;
} */


.theme-light .menu-item-link {
  color: #757575;
}

.theme-light .table thead th {
  /* background-color: rgba(33, 150, 243, 0.4) !important; */
  /* background-color: #bcdefa !important; */
  background-color: #509fd94f !important;
  /* box-shadow: inset 0 0 0 9999px #2196f31a; */
  border-bottom-width: 1px;
  /* background: #E2F1FC !important; */
  /* position: relative; */
}

.react-tabs__tab:focus:after {
  background: transparent;
}

.hv-center {
  position: absolute;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  top: 60%;
  left: 50%;
  width: 25%;
  padding: 50px 30px;
}

.message {
  position: absolute;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  padding: 50px 30px;
  left: 60%;
  top: 55%;
  bottom: 50%;
  right: 50%;
  width: 30%;
  height: max-content;
  background-color: #bcdefa !important;
  border-radius: 10px;
  box-shadow: 0px 0px 0px #bebebe, -10px -10px 30px #ddd;
}

.message h4 {
  font-size: 14px;
  color: #000;
  line-height: 24px;
  font-weight: 600;
}

.w-set .css-b62m3t-container {
  width: -webkit-fill-available;
}

.theme-light .table-hover tbody tr:hover {
  background: transparent;
  cursor: inherit;
}

.table.table-bordered {
  background: #fff;
}

.react-tabs__tab {
  font-size: 14px;
  font-weight: 600;
}

#apexchartsbasicxbar .apexcharts-toolbar,
#apexchartsbasicxbar .apexcharts-yaxistooltip {
  z-index: -1;
}

.module-expand-modal.modal {
  position: relative;
}

.module-expand-modal.modal.show .modal-dialog {
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  left: 50%;
  width: 100%;
}

.module-expand-modal.modal .css-6j8wv5-Input {
  padding: 0;
  margin: 0;
}

.tab-buttons {
  padding: 0;
  border-bottom: 1px solid rgba(33, 150, 243, 0.4);
}


.snav-pills.btn-outline-primary:hover {
  color: #2196f3;
  background-color: #f5f5f5;
  /* border-color: #0d6efd; */
}

.left-side.d-flex .ant-input-group-wrapper {
  border: 1px solid #fff;
}

.ant-table-tbody>tr>td {
  overflow-wrap: anywhere;
}

.theme-light .common-form-fields input[type="date"] {
  color-scheme: "light" !important;
}

.theme-light .common-form-fields input[type="date"]:focus {
  border: 1px solid #d4dee7 !important;
}

.App {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}

.sevolatality-border .ant-table-thead tr th.ant-table-cell {
  display: none;
}

.sevolatality-border .ant-table-content table tr,
.sevolatality-border .ant-table-content table tr td {
  border: 0;
}

/* .sevolatality-border td:nth-child(2) {
  min-width: 100%;
} */

.ga-calender .btn-primary.dropdown-toggle {
  width: 32px;
  height: 30px;
  background: #f5f5f5 !important;
  border-color: #f5f5f5 !important;
  line-height: 35px;
  text-align: center;
  border-radius: 5px;
  padding: 0px 7px;
}

.ga-calender .btn-primary.dropdown-toggle:hover {
  background: #2196f3 !important;
  border-color: #2196f3 !important;
}

.ga-calender .fa.fa-download {
  color: #15c2d7;
}

.ga-calender .fa.fa-download:hover {
  color: #fff;
}

.ga-calender .dropdown-toggle::after {
  display: none;
}

.range-text {
  background: #2196f3;
  color: #fff;
  font-size: 30px;
  padding: 15px;
  text-align: center;
}

.category-range {
  text-align: center;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  padding: 8px;
  border-radius: 50px;
  text-transform: capitalize;
}

.category-high {
  background: #dc8a1f;
}

.high-box {
  height: 20px;
  width: 30px;
  background-color: #dc8a1f;
}

.category-normal {
  /* background: #d4edda; */
  background: #539b3e;
}

.normal-box {
  height: 20px;
  width: 30px;
  background-color: #539b3e;
}

.category-low {
  /* background: #cce5ff; */
  background: #358ac3;
}

.low-box {
  height: 20px;
  width: 30px;
  background-color: #358ac3;
}

.category-vhigh {
  background: #ba4636;
}

.veryhigh-box {
  height: 20px;
  width: 30px;
  background-color: #ba4636;
}

.sevolatality .low-range {
  color: #358ac3;
}

.roles-tab .justify-content-end {
  margin-top: 8px;
}

.sevolatality-border td:first-child.ant-table-cell div {
  color: #222 !important;
}

.sevolatality-border td:first-child.ant-table-cell:hover div {
  color: #0d6efd !important;
}

.cloud-img img {
  width: 80px;
  text-align: center;
}

.designation-box input[type='checkbox'] {
  height: 15px;
  width: 15px;
  display: inline-flex;
  vertical-align: middle;
}

.designation-box span {
  margin-top: -1px;
  font-size: 13px;
}

.gsc-checkbox input[type='checkbox'] {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
}

.googleanalytics input[type='checkbox'] {
  width: 1.5em;
  height: 1.5em;
  vertical-align: sub;
}

.btn-outline-secondary {
  color: #757575;
  border-color: #757575;
}

.assign-search .css-6j8wv5-Input {
  height: 30px;
}

.btn-primary:hover {
  color: #fff;
  background-color: #1c80cf;
  border-color: #1a78c2;
}

.form-select:focus {
  box-shadow: none;
}


.form-control {
  height: 40px;
  font-size: 12px;
}

.form-control.invalid-control {
  border-color: red;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control.valid-control {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.forgotpass a:not([href]):not([tabindex]) {
  color: #2196f3;
  text-decoration: none !important;
}

.forgotpass a:not([href]):not([tabindex]):hover {
  color: #1a78c2;
}

.breakdown h1 {
  font-size: 18pt;
  color: #980000;
  font-weight: 700;
}

select::after option:hover {
  background: #f5f5f5 !important;
  color: #2196f3 !important;
}

.keyword-type-table .table thead th {
  background-color: #bcdefa !important;
}

.plus-minus-btn button {
  /* border-radius: 4px; */
  border-style: solid;
  border-width: 1px;
  height: 38px;
  width: 38px;
  border-color: #e0e0e0;
  background: transparent;
  text-align: center;
  /* margin: 7px 0 6px 8px; */
  border: 1px solid #d1d1d1 !important;
  border-radius: 4px;
}

.keyword-size {
  font-size: 14px;
}

.share.bar-link-icon a {
  width: 32px;
  height: 30px;
  background: #f5f5f5;
  border-color: #f5f5f5;
  line-height: 35px;
  text-align: center;
  border-radius: 5px;
}

.btn-primary.dropdown-toggle {
  /* background-color: rgba(33, 150, 243, .1); */
  background: #2196f31a !important;
  border-color: transparent !important;
  border-radius: 5px;
  width: auto;
  color: #2196f3;
}

.download-filter .btn-primary.dropdown-toggle {
  background-color: #f5f5f5 !important;
  border-radius: 5px;
  width: auto;
  color: #15c2d7 !important;
  border-color: #f5f5f5 !important;
}

.download-filter .btn-primary.dropdown-toggle:hover {
  color: #fff;
  background: #2196f3 !important;
}

.buttonBack___1mlaL,
.buttonNext___2mOCa {
  position: absolute;

  height: 40px;
}

.carousel__back-button {
  left: -20px;
}

.carousel__next-button {
  right: -20px;
  top: 45%;
}

.slideInner___2mfX9 {
  position: inherit;
}

.carousel {
  max-height: 400px;
  padding: 0 30px;
}

.theme-light .border.audit {
  border: 1px solid #2196f3 !important;
}

.btn-group .btn.btn-outline-primary {
  padding-left: 29px;
  padding-right: 30px;
}

/* .fa.fa-caret-right,
.fa.fa-caret-left {
  border: solid 1px #d4dee7;
  font-size: 20px;
  text-align: center;
  padding: 0px 5px;
  border-radius: 3px;
} */

.fa.fa-angle-left.back-arrow {
  font-size: 15px;
  text-align: center;
  padding: 3px 0px;
  border-radius: 15px;
  background: #2196f3;
  color: #fff;
  width: 22px;
  height: 22px;
  border: 1px solid #2196f3;
}

.fa.fa-angle-left.back-arrow:hover {
  background-color: #1c80cf;
  border-color: #1c80cf;
}


.col-md-3.seo-volatality {
  overflow-y: scroll;
  height: 725px;
}

/* .col-md-3.seo-volatality ::-webkit-scrollbar {
  max-height: 50%;
  height: 50%;
} */

.col-md-3.seo-volatality .seo-port {
  position: relative;
  margin-bottom: 0px !important;
}

.col-md-3.seo-volatality::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #F5F5F5;
  border-radius: 10px;
  height: 50px;
}

.col-md-3.seo-volatality::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
  height: 50px;
}

.col-md-3.seo-volatality::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0.44, rgb(111, 111, 111)), color-stop(0.72, rgb(111, 111, 111)), color-stop(0.86, rgb(111, 111, 111)));
  height: 50px;
}

.rank-tracking table.ranktracking-table2 td:nth-child(8) .ant-table-column-sorters.ant-table-column-sorter.ant-table-column-sorter-full {
  display: none;
}

.rank-tracking-arrow .fa.fa-angle-right,
.rank-tracking-arrow .fa.fa-angle-left {
  position: relative;
  z-index: 99;
  top: 45px;
  font-size: 18px;
  right: 10px;
  color: #222;
  border: 2px solid #4c4c4c;
  height: 25px;
  width: 25px;
  text-align: center;
  padding: 2px;
  border-radius: 5px;
  background: #f5f5f5;
}

.rank-tracking-arrow .fa.fa-angle-right:hover,
.rank-tracking-arrow .fa.fa-angle-left:hover {
  color: #fff;
  background: #575757;
}


.roi-accordian .accordion-button{
  background-color: #fff;
  color: #222;
}

.roi-accordian .accordion-button.active{
  background-color: #a6d5fa;
  color: #222;
}

.roi-btn-box{
  position: relative;
}

.roi-btn-box .roi-acco-btn{
  position: absolute;
  right: 20px;
  top: 10px;
  z-index: 99;
}

.accordion-button:not(.collapsed)::after{
  display: none;
}

.roi-accordian .feather-chevron-down{
  height: auto;
}

.month {
  background-color: #F5F5F5;
  border: 1px solid #f5f5f5;
  border-radius: 70px;
  width: 15%;
  height: 35px;
}

.month:hover {
  background-color: #2196f3;
  border-color: #2196f3;
  color: #fff;
}

.bar-link-icon .filter-popup.gsc-filter {
  min-width: 400px;
}

.queries h3 {
  font-size: 14px;
}

.ant-checkbox-wrapper .ant-checkbox-inner {
  border: 1px solid #d9d9d9;
}

.plusminus .fa.fa-plus-circle,
.plusminus .fa.fa-minus-square {
  font-size: 18px;
}

/* .content-chart .apexcharts-svg {
  padding: 0px 55px;
} */

/* .ranktracking-table1 .ant-table-content table tr {
  padding-bottom: 0;
} */

.gabox-shadow .avg-val-positive {
  color: #008000 !important;
}

.gabox-shadow .avg-val-negative {
  color: #ff0000 !important;
}

/* divya */
.avg-val-negative {
  color: #F64F53;
}

/* divya */
.avg-val-positive {
  color: #91E395;
}

.click-domain2 .avg-val-negative,
.click-domain2 .avg-val-positive {
  text-align: right;
}

.click .avg-val-negative,
.click .avg-val-positive {
  text-align: left;
}

.theme-light .gabox-shadow .avg-val {
  color: #000000 !important;
}

.bug-link {
  color: #222;
}

.bug-link:hover {
  color: #fff;
}

.fa.fa-bug {
  font-size: 1.25rem;
  padding: 8px;
}

.bug-dropdown {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  height: 30px;
  width: 32px;
  border-radius: 5px;
  padding: 0.375rem .75rem;
}

.bug-dropdown:hover {
  background: #2196f3;
}

.google-analytics-table.table .ant-table-thead>tr>th:nth-child(2) {
  min-width: 265px;
}

.ga-table.google-analytics-table .ant-table-thead>tr>th:nth-child(2) {
  min-width: 220px;
}

.comment {
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gscc-table .ant-table-thead>tr>th:nth-child(2) {
  min-width: 150px;
}

.gscc-table .ant-table-thead>tr>th {
  min-width: 170px;
}

.float-btn-right.bug-btn {
  position: fixed;
  top: 57.6%;
  right: -3px;
  /* transform: translateY(-55%); */
}

.bug {
  font-size: 1.2rem;
}

.keyword-type-table.table-modules tbody>tr>td {
  padding: 16px 16px;
  box-shadow: none;
}


.keyword-type-table th:first-child {
  min-width: 300px;
}

.keyword-type-table th:nth-child(2) {
  min-width: 300px;
}


.keyword-type-table th:nth-child(4) {
  min-width: 0;
}

.keyword-type-table .d-flex.plusminus {
  padding: 5px 0px;
}

.estimated-click-share td:nth-child(2) {
  min-width: 200px;
}

.estimated-click-share th:nth-child(3),
.estimated-click-share th:nth-child(4),
.estimated-click-share th:nth-child(6),
.estimated-click-share th:nth-child(7) {
  min-width: 170px;
}

.apexcharts-toolbar .apexcharts-zoomin-icon,
.apexcharts-toolbar .apexcharts-zoomout-icon,
.apexcharts-toolbar .apexcharts-pan-icon,
.apexcharts-toolbar .apexcharts-reset-icon,
.apexcharts-toolbar .apexcharts-selected {
  display: none;
}

.bug-modal {
  position: fixed;
  right: 0;
  left: inherit;
  top: 0;
}

.bug-btn .btn-demo {
  padding: 0;
}

.bug-modal .modal-body {
  white-space: normal;
}

/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/

.modal.right.bug-modal .modal-dialog {
  position: fixed;
  margin: auto;
  width: 470px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}


.modal.right.bug-modal .modal-content {
  height: 100%;
  overflow-y: auto;
}


.modal.right.bug-modal .modal-body {
  padding: 15px 15px 80px;
}


/*Right*/
.modal.right.fade.bug-modal .modal-dialog {
  right: 0px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0;
}

/* ----- MODAL STYLE ----- */
.bug-modal .modal-content {
  border-radius: 0;
  border: none;
}

.bug-modal .modal-header {
  border-bottom-color: #EEEEEE;
  background-color: #FAFAFA;
}

.bug-modal .portlet-footer {
  position: fixed;
  bottom: 70px;
  right: 15px;
  width: -webkit-fill-available;
}


.sow-create select {
  height: 3rem;
  border: 1px solid #212121 !important;
  border-radius: 8px;
  padding: 10px 16px;
  /* width: 27.375rem !important; */
}

/* .individual-SOW {
  overflow-y: inherit;
  height: inherit !important;
} */
.subticket-modal .width-available {
  width: -webkit-fill-available;
}

.scope-unit .form-control {
  height: 3rem;
  border: 1px solid #212121 !important;
  border-radius: 8px;
  padding: 10px 16px;
  /* width: 23.875rem !important; */
}

.close span {
  font-size: 25px;
  color: #000000;
  padding-right: 15px;
}

.carousel .fa.fa-angle-right {
  font-size: 1.2rem;
}

.estimate-table .information:hover+.info-hide {
  right: 3%;
  bottom: 0;
}

.info-hide .col-md-6 {
  text-align: start;
}

.clickshare-trend .clickshare-info .information {
  bottom: 35px;
}

.page-speed-second .ant-table-tbody .ant-table-selection-column,
.page-speed-second .ant-table-thead .ant-table-selection-column,
.page-speed-second .ant-table-tbody .ant-table-cell-fix-left,
.page-speed-second .ant-table-thead .ant-table-cell-fix-left {
  position: unset !important;
  left: unset !important;
}

.bug-file {
  /* margin-top: 10px; */
  margin-bottom: 0px;
  /* background-color: #eeee; */
  border-radius: 5px;
}

.report-file {
  background-color: #eeee;
  padding: 0px 10px;
  margin-top: 10px;
  border-radius: 5px;
}

.siteuptime-table td:nth-child(2) {
  min-width: 145px;
}

.siteuptime-table td:nth-child(3) {
  min-width: 300px;
}

.siteuptime-table td:nth-child(4) {
  min-width: 250px;
}

.siteuptime-table td:nth-child(5),
.siteuptime-table td:nth-child(6),
.siteuptime-table td:nth-child(7),
.siteuptime-table td:nth-child(8) {
  min-width: 180px;
}

.rank-distribution th.ant-table-cell {
  min-width: 140px;
}

.estimated .row>* {
  padding-left: 0 !important;
}

.page-speed-second td:nth-child(2) {
  min-width: 200px;
}

.keyword-frequency-table th:nth-child(2) {
  min-width: 300px;
}

.box1 {
  color: #008000;
}

.blue-box .box {
  background: rgb(0, 143, 251);
}

.blue-box .green-bx {
  background: rgb(0, 227, 150);
}

.blue-box .yellow-bx {
  background: rgb(254, 176, 25);
}

.blue-box .red-bx {
  background: rgb(255, 69, 96);
}

.blue-box .purple-bx {
  background: rgb(119, 93, 208);
}

.blue-box .box,
.blue-box .greenbox {
  border: 1px solid #ddd;
  padding: 0;
  width: 15px;
  height: 15px;
  margin-right: 8px;
}

/* .page-speed-second .scroller {
  width: 300px;
  height: 100px;
} */
.blue-box .box {
  background: rgb(0, 143, 251);
}

.blue-box .green-bx {
  background: rgb(0, 227, 150);
}

.blue-box .yellow-bx {
  background: rgb(254, 176, 25);
}

.blue-box .red-bx {
  background: rgb(255, 69, 96);
}

.blue-box .purple-bx {
  background: rgb(119, 93, 208);
}



.page-speed-second .ant-table::-webkit-scrollbar {
  height: 12px !important;
  border: 0;
  border-radius: 0;
  width: 10px !important;
}

/* Track */
.page-speed-second .ant-table::-webkit-scrollbar-track {

  width: 10px !important;
  border: 0;
  border-radius: 0;
  /* background: #f1f1f1; */
}

/* Handle */
.page-speed-second .ant-table::-webkit-scrollbar-thumb {

  width: 10px !important;
  background: #bbb7b7;
  border-radius: 0;
  border: 0;
}

.organic.ROI-outer {
  border: 0;
  box-shadow: none;
}

.cpc.ROI-inner {
  padding: 5px 30px;
  /* width: 50%; */
  border-radius: 0;
  border: 1px solid #2196f3;
  background: #2196f3;
  border-right: 1px solid #eeeeee47;
}

.cpc.ROI-inner h4,
.cpc.ROI-inner p {
  color: #fff !important;
}

/* .content-chart *,
.content-chart tspan,
.content-chart .apexcharts-text *,
.content-chart .apexcharts-text text *,
.content-chart .apexcharts-text text.apexcharts-text *,
.content-chart .apexcharts-text .apexcharts-text * {
  margin: 0 15px !important;
} */


.content-chart .apexcharts-text *,
.content-chart .apexcharts-text tspan,
.content-chart .apexcharts-text title,
.content-chart .apexcharts-text {
  margin: 0 15px !important;
}

.roi-datepicker {
  cursor: pointer;
}

/* .css-15wwp11-MuiTableHead-root {
  display: block;
} */

/* .table-2-design .MuiPaper-root table thead th {
  background-color: #bcdefa !important
} */

.dropdown.d-inline .btn.btn-default.dropdown-toggle {
  border: none;
}

.rank-chart {
  right: 350px !important;
}

/* .cpc-table .ant-table-cell,
.cpl-table .ant-table-cell {
  min-width: 50px;
}

.ant-table-container table>thead>tr:first-child th:first-child {
  min-width: 50px;
} */

.cpc-table .ant-table-container table>thead>tr .ant-table-cell {
  max-width: 76px;
}

.pagespeed-table th:nth-child(2) {
  min-width: 300px;
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

.display-flex {
  display: flex;
}

.click.btn.btn-primary {
  width: max-content;
}

.click.btn.btn-primary .fa-chevron-down{
  margin-left: 5px;
}
/* button#dropdownMenuButton {
  
  color: #212121 !important;
  background: #EEEEEE !important;
  border-color: #f5f5f5 !important;
  height: 3rem;
  width: 3rem;
  border-radius: 0.375rem;
  margin-left: 5px;
} */

.avatar-display div {
  /* border: none;
  background-color: transparent; */
  color: #212121 !important;
  background: #EEEEEE !important;
  border-color: #f5f5f5 !important;
  border-radius: 0.375rem;
  margin-left: 5px;
  height: 3rem;
  width: 3rem;
  padding: 10px;
}

.profile-pic .dropdown-toggle::after {
  margin-left: 12px !important;
}

.audit-editor .jodit_sticky>.jodit-toolbar__box {
  top: -20px;
}

.audit-textarea,
.audit-textarea div,
.audit-textarea span,
.audit-textarea p,
.audit-textarea th,
.audit-textarea tr,
.audit-textarea td,
.audit-textarea ol,
.audit-textarea li {
  font-family: 'Montserrat' !important;
  white-space: normal !important;
  font-weight: normal !important;
}


.audit-textarea h1,
.audit-textarea h2,
.audit-textarea h3,
.audit-textarea h4,
.audit-textarea h5,
.audit-textarea h6 {
  font-family: 'Montserrat' !important;
  white-space: normal !important;
}

.audit-editor .jodit-wysiwyg {
  font-family: 'Montserrat' !important;
  white-space: normal !important;
  width: 840px !important;
  overflow-x: hidden !important;
  font-weight: normal !important;
}

.audit-issue-form .jodit-wysiwyg,
.audit-issue-form .jodit-wysiwyg div,
.audit-issue-form .jodit-wysiwyg span,
.audit-issue-form .jodit-wysiwyg p,
.audit-issue-form .jodit-wysiwyg th,
.audit-issue-form .jodit-wysiwyg tr,
.audit-issue-form .jodit-wysiwyg td,
.audit-issue-form .jodit-wysiwyg ol,
.audit-issue-form .jodit-wysiwyg li {
  font-family: 'Montserrat' !important;
  white-space: normal !important;
  font-weight: normal !important;
}

.audit-issue-form .jodit-wysiwyg h1,
.audit-issue-form .jodit-wysiwyg h2,
.audit-issue-form .jodit-wysiwyg h3,
.audit-issue-form .jodit-wysiwyg h4,
.audit-issue-form .jodit-wysiwyg h5,
.audit-issue-form .jodit-wysiwyg h6 {
  font-family: 'Montserrat' !important;
  white-space: normal !important;
}

.auditpdf div,
.auditpdf span,
.auditpdf p,
.auditpdf tr,
.auditpdf td,
.auditpdf ol,
.auditpdf li {
  font-family: 'Montserrat' !important;
  white-space: normal !important;
  font-weight: normal !important;
}


.auditpdf th {
  font-family: 'Montserrat' !important;
  white-space: normal !important;
}

.auditpdf h1,
.auditpdf h2,
.auditpdf h3,
.auditpdf h4,
.auditpdf h5,
.auditpdf h6 {
  font-family: 'Montserrat' !important;
  white-space: normal !important;
}

.auditpdf h1,
.audit-textarea h1,
.audit-issue-form .jodit-wysiwyg h1,
.audit-editor .jodit-wysiwyg h1 {
  font-size: 30px !important;
}

.auditpdf h2,
.audit-textarea h2,
.audit-issue-form .jodit-wysiwyg h2,
.audit-editor .jodit-wysiwyg h2 {
  font-size: 25px !important;
}

.auditpdf h3,
.audit-textarea h3,
.audit-issue-form .jodit-wysiwyg h3,
.audit-editor .jodit-wysiwyg h3 {
  font-size: 20px !important;
}

.auditpdf h4,
.audit-textarea h4,
.audit-issue-form .jodit-wysiwyg h4,
.audit-editor .jodit-wysiwyg h4 {
  font-size: 18px !important;
}

.auditpdf h5,
.audit-textarea h5,
.audit-issue-form .jodit-wysiwyg h5,
.audit-editor .jodit-wysiwyg h5 {
  font-size: 14px !important;
}

.auditpdf h6,
.audit-textarea h6,
.audit-issue-form .jodit-wysiwyg h6,
.audit-editor .jodit-wysiwyg h6 {
  font-size: 12px !important;
}

.auditpdf th,
.audit-textarea th,
.audit-issue-form .jodit-wysiwyg th,
.audit-editor .jodit-wysiwyg th {
  font-size: 14px !important;
}

.auditpdf div,
.auditpdf span,
.auditpdf p,
.auditpdf tr,
.auditpdf td,
.auditpdf ol,
.auditpdf li,
.audit-textarea div,
.audit-textarea span,
.audit-textarea p,
.audit-textarea tr,
.audit-textarea td,
.audit-textarea ol,
.audit-textarea li,
.audit-issue-form .jodit-wysiwyg div,
.audit-issue-form .jodit-wysiwyg span,
.audit-issue-form .jodit-wysiwyg p,
.audit-issue-form .jodit-wysiwyg tr,
.audit-issue-form .jodit-wysiwyg td,
.audit-issue-form .jodit-wysiwyg ol,
.audit-issue-form .jodit-wysiwyg li .audit-editor .jodit-wysiwyg div,
.audit-editor .jodit-wysiwyg span,
.audit-editor .jodit-wysiwyg p,
.audit-editor .jodit-wysiwyg tr,
.audit-editor .jodit-wysiwyg td,
.audit-editor .jodit-wysiwyg ol,
.audit-editor .jodit-wysiwyg li {
  font-size: 13px !important;
  text-align: unset !important;
  font-weight: normal !important;
}

/* .auditpdf div {
  margin-left: 0 !important;
} */

/* .auditpdf div table {
  width: auto;
} */

.auditpdf .text-right {
  text-align: right !important;
}

.auditpdf .text-center {
  text-align: center !important;
}

.auditpdf .project-name {
  font-size: 30px !important;
}

.auditpdf .point-title {
  font-size: 20px !important;
}

.audit-editor .jodit-wysiwyg h1,
.audit-editor .jodit-wysiwyg h2,
.audit-editor .jodit-wysiwyg h3,
.audit-editor .jodit-wysiwyg h4,
.audit-editor .jodit-wysiwyg h5,
.audit-editor .jodit-wysiwyg h6 {
  font-family: 'Montserrat' !important;
  white-space: normal !important;
}

.audit-editor .jodit-wysiwyg div,
.audit-editor .jodit-wysiwyg span,
.audit-editor .jodit-wysiwyg p,
.audit-editor .jodit-wysiwyg tr,
.audit-editor .jodit-wysiwyg td,
.audit-editor .jodit-wysiwyg ol,
.audit-editor .jodit-wysiwyg li {
  font-family: 'Montserrat' !important;
  white-space: normal !important;
  font-weight: normal !important;
}


.audit-editor .jodit-wysiwyg th {
  font-family: 'Montserrat' !important;
  white-space: normal !important;
}

.audit-editor .jodit-react-container {
  width: 875px;
}

.margin-right-15 {
  margin-right: -20px;
}


.margin-left-15 {
  margin-left: -5px;
}

.scope-A,
.scope-plus {
  text-align: center;
}

.scope-plus {
  margin-top: 4px;
}

.scope-table .ant-table-tbody>tr>td:last-child {
  padding: 10px 15px;
}

.scope-table .ant-table-tbody td:nth-child(2) span {
  overflow: hidden;
  width: 80px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.scope-table .ant-table-thead th:nth-child(3) span {
  overflow: hidden;
  width: 62px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}


.scope-plus span {
  display: block;
  border-radius: 100%;
  background: #EEEEEE;
  color: #757575;
  width: 22px;
  height: 22px;
  line-height: 20px;
  border: 1px solid #757575;
  font-size: 2rem;
}

.scope-A span {
  display: block;
  border-radius: 100%;
  background: #E2F1FC;
  color: #0691EF;
  width: 22px;
  height: 22px;
  border: 1px solid #0691EF;
  padding: 1px 2px;
  font-size: 1rem;
  margin-left: -10px;
  font-weight: 500;
  line-height: 1.5rem;
}

.scope-table .margin-left-minus span {
  margin-left: -24px;
}


.contentword-table.dash-table th:nth-child(5) {
  min-width: 300px;
}

.contentword-table.dash-table th:nth-child(4) {
  min-width: 150px;
}

.editor-image .ms-5.bg-transparent table {
  width: auto !important;
}

.setting-link-icon .setting-popup {
  position: absolute;
  border: 0;
  border-radius: 0;
  /* background: #fff; */
  top: 55px;
  right: -25px;
  width: 100%;
  height: 100vh;
  padding: 25px;
  /* max-width: 700px; */
  min-width: 1210px;
  /* z-index: 999999; */
  z-index: 1;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
}

.new-organization-popup .close.organization-close-btn {
  position: absolute;
  right: 10px;
}

.drop-organization .setting-popup.new-organization-popup {
  position: absolute;
  border: 0;
  border-radius: 0;
  background: #fff;
  top: -18px;
  right: 0px;
  width: 100%;
  height: 150vh;
  /* min-width: 1210px; */
  /* z-index: 999999; */
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);

}

.drop-organization .setting-popup.new-organization-popup {

  z-index: 99;


}

.dropdown-menu.setting-dropdown.show {
  background: transparent;
  border: 0;
}

.setting-popup label {
  font-size: 1.5rem;
  font-weight: 500;
  cursor: pointer;
  color: #000000;
}

.portlet.setting-popup {
  height: 100vh !important;
}


.setting-tab img {
  /* background: #E2F1FC; */
  height: 3.25rem;
  width: 3.25rem;
  padding: 0px;
  border-radius: 6px;
  font-size: 6px;
}

.setting-tab .border {
  border: 1px solid #B9DCFA !important;
  border-radius: 4px;
  /* width: 22.563rem !important; */
  height: 6.75rem;
  padding-left: 10px;
}

.setting-tab {
  width: 100%;
  height: 8rem !important;
}

.form-control:focus.client-search,
.form-control:focus-visible.client-search,
.form-control:focus-within.client-search,
.form-control:active.client-search

/* input#input-s:focus-visible { */
  {
  border: 1px solid #CBCBCB !important;
}

.form-control:focus.client-search.active,
.form-control:focus.client-search:hover {
  border: 1px solid #2196f3 !important;
}


.modal.center.bug-modal .modal-dialog {
  /* position: fixed; */
  margin: auto;
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(20%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
  top: 20px;
}

.config-clientlist p:hover,
.clientlist p a p:hover {
  background-color: #E2F1FC;
}

/* .config-clientlist span:active {
  background-color: #2196f31a;
}



.config-clientlist span:active,
.config-clientlist span:focus {
  color: #2196f3 !important;
} */

.config-clientlist .client.active:active {
  color: #2196f3;
  background-color: #2196f31a;
}

/* .config-clientlist .client.active {
  color: #222;
  background-color: transparent;
} */

.config-clientlist .client-btn {
  color: #0691EF;
  background-color: #E2F1FC !important;
  padding: 3px;
  margin: 0;
  padding-left: 15px !important;
  font-weight: 400;
}

/* .config-clientlist .client-btn, */
.config-clientlist p a {
  width: 100%;
}

.menu-item-text.client-btn {
  color: #0691EF;
  background-color: #E2F1FC;
  padding: 3px;
  margin: 0;
  padding-left: 15px !important;
}

.set.setting-link-icon .fa.fa-cog,
.organization-edit .fa.fa-edit {
  font-size: 14px;
}

.set.setting-link-icon .fa.fa-cog:hover,
.organization-edit .fa.fa-edit:hover,
.client-edit .fa.fa-edit:hover,
.project-edit .fa.fa-edit:hover {
  color: #2196f3;
}



/* .theme-light .nav-pills .nav-link.active,
.theme-light .nav-pills .show>.nav-link {
  color: #222;
  background: #2196f31a;
} */

.nav-pills .nav-link {
  color: #2196f3;
}

.user-tab .nav-pills .nav-link.active {
  padding: 10px 30px !important;
}

.department-save {
  background: #2196f31a;
  border: 1px solid #2196f31a;
  width: 70px;
  height: 30px;
  border-radius: 4px;
  color: #222;
}

.clients-accordian .accordion-button:focus,
.leads-accordian .accordion-button:focus {
  border-color: transparent;
  box-shadow: none;
  border: transparent !important;
}

.clients-accordian .accordion-item {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.clients-accordian .accordion-button,
.leads-accordian .accordion-button {
  padding-right: 35px;
}

.modal.right.bug-modal.add-department-modal .modal-dialog {
  width: 100%;
}

.modal.right.fade.bug-modal.add-department-modal.show {
  background-color: rgba(0, 0, 0, 0.6);
}

.modal.right.fade.bug-modal.edit-department-modal.show {
  background-color: rgba(0, 0, 0, 0.6);
}

.modal.right.fade.bug-modal.edit-department-modal.show .modal-content {
  height: 100%;
}

.modal.right.bug-modal.edit-department-modal .modal-dialog {
  width: 100%;
}

.clients-accordian.accordion .accordion-button:not(.collapsed),
.leads-accordian.accordion .accordion-button:not(.collapsed) {
  box-shadow: none;
  background: #B9DCFA !important;
  border-radius: 0;
}

.admin-submenu .Clients-list {
  overflow-y: auto;
  height: 90vh;
  overflow-x: hidden;
}

.admin-submenu .projectsList {
  overflow-y: auto;
  height: 90vh;
  overflow-x: hidden;
}

.admin-submenu .viewList {
  overflow-y: auto;
  height: 90vh;
  overflow-x: hidden;
}

.google-analytics-table .ant-table-row-expand-icon-expanded {
  display: none;
}

.lead-accordian .fa.fa-edit:hover {
  color: #2196f3 !important;
}

.scope-calendar div#example-collapse-text-calendar {
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  position: absolute;
  z-index: 9999;
  right: auto;
}

.widget15-compact .client-search::placeholder {
  color: #777777;
  font-size: 1.125rem !important;
  font-weight: 400;
}

.widget15-compact .client-search {
  height: 30px !important;
  border-radius: 6px !important;
}

.clientlist p a p,
.config-clientlist p a p {
  padding-left: 15px !important;
  font-weight: 400;
  color: #000000;
  font-size: 1.125rem;
}

.evaluation-cal div#example-collapse-text-calendar {
  right: 49px !important;
  z-index: 1;
}

.role-list {
  border-bottom: 1px solid #777777;
  margin-bottom: 10px;
}

.role-items {
  margin-bottom: 5px;
  color: #000000;
  text-transform: capitalize;
  font-weight: 500;
}

.team-member-table th:nth-child(even) {
  min-width: 175px;
}

.team-member-table th:nth-child(odd) {
  min-width: 130px;
}

.team-member-table th:first-child {
  min-width: 50px;
}

.team-member-table th:last-child {
  min-width: 100px !important;
}

.team-member-table th:nth-child(12) {
  min-width: 115px !important;
}

.team-member-table th:nth-child(11) {
  min-width: 250px !important;
}

.team-member-table th:nth-child(2),
.team-member-table th:nth-child(6) {
  min-width: 170px !important;
}

.team-member-table th:nth-child(4) {
  min-width: 130px !important;
}


#sticky-header-desktop-sticky-wrapper {
  position: relative;
  z-index: 1;
}

.scope-table div#sample-module-expand {
  overflow-x: scroll;
}

.scope-work-btn .btn.btn-light.btn-icon {
  background: #0691EF;
  padding: 9px;
  border: 1px solid #E2E8F0;
  color: #FFFFFF;
  font-weight: 600;
  width: 10.125rem !important;
  height: 3rem !important;
  font-size: 1rem;
}

.scope-work-btn .btn.btn-light.btn-icon:hover {
  color: #fff;
  background: #1c80cf;
  border-color: #1a78c2;
}

.scope-table th:first-child {
  min-width: 55px !important;
}

.scope-table th:nth-child(5) {
  min-width: 85px;
}

.scope-table th:nth-child(6) {
  min-width: 70px;
}


.scope-table th:nth-child(10) {
  min-width: 130px;
}


/* .modal-dialog.scope-modal {
  width: 58.813rem !important;
  height: 67.5rem !important;
} */

/* .scope-choose-file {
  background-color: #E2F1FC;
  border-radius: 0.375rem;
  border: 1px solid #E2E8F0;
  width: 35rem;
  height: 3.813rem;
} */

.file-not-select {
  color: #757575;
  font-size: 0.875rem;
  font-weight: 500;
  margin: 4px !important;
}

.modal-dialog.scope-modal .common-form-fields .active .radio {
  border: 1px solid #0691EF;
  width: 100%;
  height: max-content;
}

.upload-scope-modal .ant-drawer-content-wrapper {
  width: 40% !important;
}

.create-subticket-modal .ant-drawer-content-wrapper {
  width: 40% !important;
}

.edit-industries .ant-drawer-content-wrapper {
  width: 40% !important;
}

.add-industries .ant-drawer-content-wrapper {
  width: 40% !important;
}

.create-subticket-modal .ant-drawer-body {
  padding: 0;
}

.individual-SOW label {
  font-size: 1rem;
  font-weight: 500;
  color: #212121 !important;
}

.individual-SOW select,
.individual-SOW input {
  border: 1px solid #757575 !important;
  border-radius: 0.5rem !important;
  height: 3rem;
  color: #757575;
  font-weight: 500;
  font-size: 1rem;
}



/* .clients-accordian.accordion .collapse:not(.show) {
  display: block !important;
} */

.clients-accordian.accordion .form-label,
.leads-accordian.accordion .form-label {
  color: #000000;
  font-size: 1.125rem;
  font-weight: 400;
}

/* .organization-table .ant-table-tbody>tr>td {
  padding: 4px;
} */

.organization-table th:last-child {
  min-width: 110px;
}

.organization-table .ant-table-pagination.ant-pagination {
  margin: 0;
}

.add-department-modal .common-form-fields input[type="text"]::placeholder {
  color: #777777;
  font-size: 10px;
  font-weight: 400;
}

.add-department-modal .common-form-fields select.edit-role-select,
.edit-department-modal .common-form-fields select.edit-role-select {
  width: 70px;
}


.add-department-modal .common-form-fields select,
.bug-modal.edit-department-modal .common-form-fields select,
.edit-department-modal .common-form-fields input[type="text"],
.add-department-modal .common-form-fields input[type="number"],
.add-department-modal .common-form-fields input[type="text"],
.edit-department-modal .common-form-fields input[type="number"] {
  border: 1px solid #777777;
  border-radius: 6px;
  padding: 5px 15px;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 24px;
  color: #000000;
}

.edit-department-modal input::placeholder,
.add-department-modal input::placeholder {
  color: #777777;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 24px;
}

.edit-department-modal .btn-primary,
.add-department-modal .portlet-footer .btn-primary,
.edit-department-modal .portlet-footer .btn-primary {
  background-color: #0691EF;
  border-radius: 6px;
}

.depart-add-btn {
  background-color: #0691EF;
  border-radius: 6px;
  /* width: 6.125rem !important; */
  /* height: 3rem !important;
  padding: 8px; */
}

.department-role.form-label {
  font-weight: 500;
}

/* divya */
.postn {
  text-align: center;
  font-weight: 700;
  color: #222;
  font-size: 14px;
  font-family: Helvetica, Arial, sans-serif;
  letter-spacing: 1px;
  margin-bottom: -15px;
}

.roicalculator .close {
  position: absolute;
  right: 5px;
}

.employee-search input::placeholder,
.employee-tab input::placeholder {
  font-size: 1.125rem;
  font-weight: 600;
  color: #CBCBCB;
  border-radius: 6px !important;
}

.input-group-prepend .fa-search::before {
  color: #0071CE;
}

.ant-input-affix-wrapper {
  border: 1px solid #CBCBCB;
  border-radius: 6px;
  height: 3rem;
  width: 27rem;
}

.employee-tab .btn.btn-primary.organization-add {
  position: absolute;
  border-left: 0px;
  border-radius: 4px;
  height: 3rem;
  right: 10px;
  width: 5.125rem;
}

/* .employee-search .input-group-icon .input-group-prepend:not(:last-child) {
  top: -2px;
} */

.employee-tab input {
  height: 3rem;
  width: 26.968rem !important;
}

.google-trend-chart .apexcharts-svg {
  padding: 0 85px;
}

.fa.fa-angle-left.detailed-back-arrow {
  font-size: 15px;
  text-align: center;
  padding-right: 10px;
  border-radius: 15px;
  color: #000000;
  width: 22px;
  height: 22px;
}

.audit-detailed-search {
  border: 1px solid #CBCBCB;
  border-radius: 6px;
}

.audit-detailed-search .search-box input {
  border: none !important;
  height: 2.938rem;
  padding: 0.625rem 1.5rem;
  border-radius: 0.375rem !important;

}

.audit-detailed-search .search-box input:focus-visible {
  outline-offset: 0px;
  outline: 0;
  border: none !important;
  border-radius: 0;
}

.audit-detailed-search .search-box button {
  border: 0;
  border-radius: 6px;
  height: 35px;
  background: #fff;
}

.audit-detailed-box .btn-filter {
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  color: #fff !important;
  height: 35px;
  padding: 4px 12px;
  border-color: #0691EF;
  background: #0691EF;
}

.btn-detailed-audit {
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  color: #fff !important;
  height: 35px;
  padding: 6px 12px;
  border-color: #0691EF;
  background: #0691EF;
}

.audit-detailed-box .btn-filter:hover,
.btn-detailed-audit:hover {
  color: #0691EF !important;
  background-color: #0691EF !important;
  border-color: #E2F1FC !important;
}

.audit-detailed-box .btn.btn-filter span,
.btn-detailed-audit span {
  color: #fff !important;
}

.audit-detailed-box .btn.btn-filter span:hover {
  color: #fff !important;
}

.audit-detailed-box .create-export-btn:hover {
  color: #fff !important;
  background-color: #0691EF;
  border-color: #0691EF;
}

.audit-detailed-box .create-export-btn {
  border-color: #E2F1FC;
  background: #E2F1FC;
  color: #0691EF;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  height: 35px;
  padding: 4px 12px;
}

.audit-detailed-title {
  font-weight: 500;
  font-size: 1.25rem;
  color: #000000;
}

.audit-detail-box .ant-table-row-indent+.ant-table-row-expand-icon {
  float: right !important;
  margin-top: 0px !important;
  margin-right: 0px !important;
}

.audit-detail-box .anticon-down {
  float: right !important;
}

.audit-detail-box .anticon-up {
  float: right !important;
}

.audit-detail-box .type-notassigned {
  color: #FC3D39;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  vertical-align: middle;
}

.audit-detail-box .audit-detail-notassigned-active {
  padding: 4px 12px;
  background: #FCEAEE;
  border: 1px solid #FC3D39;
  border-radius: 0.375rem;
  vertical-align: middle;
  text-align: center;
  height: 2.5rem;
  width: 9.188rem;
}

.audit-detail-box .type-pending {
  color: #FF8A00;
  font-weight: 500;
  font-size: 1rem;
  /* text-align: center;
  padding: 14px; */
  vertical-align: middle;
}

.audit-detail-box .audit-detail-pending-active {
  padding: 4px 12px;
  background: #FFF7E2;
  border: 1px solid #FF8A00;
  border-radius: 0.375rem;
  vertical-align: middle;
  text-align: center;
  width: 7.875rem;
  height: 2.5rem;
}

.audit-detail-box .table.detail-audit-table td {
  padding: 1.688rem 1.313rem !important;
  height: 4.375rem;
  font-size: 1.125rem !important;
  font-weight: 600;
  color: #000000 !important;
  background: #ffffff !important;
}


.audit-detail-box .table.detail-audit-table td div,
.audit-detail-box .table.detail-audit-table .ant-table-row.ant-table-row-level-1 .ant-table-cell-fix-left {
  color: #000000;
  font-weight: 400;
  font-size: 1rem;
}

.audit-detail-box .type-ticket {
  color: #0691EF;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  /* padding: 14px; */
  vertical-align: middle;
}

.detail-auditname .w-100 {
  height: 3.125rem;
}

.detail-auditname select {
  color: #000000 !important;
  font-weight: 400 !important;
  font-size: 1.25rem !important;
}


.audit-detail-box .audit-detail-ticket-active {
  padding: 4px 12px;
  background: #E2F1FC;
  border: 1px solid #0691EF;
  border-radius: 0.375rem;
  vertical-align: middle;
  text-align: center;
  height: 2.5rem;
  width: 11.188rem;
}

.audit-detail-box .type-completed {
  color: #0BA49B;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  vertical-align: middle;
}

.detail-audit-table .ant-table-content {
  max-height: 430px;
  overflow-y: scroll;
}


.audit-detail-box .audit-detail-completed-active {
  padding: 4px 12px;
  background: #E6FAF8;
  border: 1px solid #0BA49B;
  border-radius: 6px;
  vertical-align: middle;
  text-align: center;
  width: 7.5rem;
  height: 2.5rem;
}

.audit-detail-box .ant-table {
  padding: 0 !important;
}

.audit-detail-box .table td,
.audit-detail-box .table th {
  vertical-align: middle !important;
  padding: 10px !important;
}

.audit-detail-box .table td {
  padding: 1.688rem 1.313rem !important;
  height: 4.375rem;
  font-size: 1.125rem !important;
  font-weight: 400;
  color: #000000 !important;
}


.audit-detail-box .table td:first-child,
.audit-detail-box .table th:first-child {
  text-align: center;
}

.audit-detail-box .table-wrapper table {
  table-layout: fixed !important;
  /* rewrite inline styles */
}

.audit-detail-box .ant-table-cell {
  z-index: 99;
  opacity: 1;
}

.audit-detail-box table>thead>tr:first-child th:first-child {
  left: 0px !important;
}

.theme-light .audit-detail-box .table thead tr:first-child th {
  background: #B9DCFA !important;
  background-color: #B9DCFA !important;
}

.theme-light .audit-detail-box .table.detail-audit-table thead tr:first-child th {
  font-size: 1.375rem !important;
  font-weight: 600;
  color: #000000 !important;
}


.theme-light .audit-detail-box .table thead tr:nth-child(2) th {
  background: #E2F1FC !important;
  background-color: #E2F1FC !important;
  font-size: 1.25rem !important;
  font-weight: 600;
  color: #000000 !important;
}



.audit-detail-box .ant-table-cell-fix-left {
  left: 0px !important;
  z-index: 999 !important;
}

.audit-detail-box .ant-table-cell-fix-left-last {
  left: 80px !important;
  z-index: 999 !important;
}

/* .dapa-table th:nth-child(1),
.dapa-table th:nth-child(2) {
  min-width: 489px;
} */

/* .dapa-table th:nth-child(1) {
width: 50%;
}

.dapa-table th:first-child {
  width: 50%;
} */

.btn.btn-primary.add-btn {
  height: 3rem;
  width: 4.125rem;
  font-weight: 600;
}

.siteuptime-table th:last-child {
  min-width: 90px;
}

.view-ticket {
  height: 14px;
  position: relative;
  top: -2px;
  left: 8px;
}

.theme-light .menu-submenu {
  background: #fff !important;
}

.newticket-view .portlet.main-view-ticket {
  height: 100%;
  position: relative;
}

.newsubticket .portlet.main-view-ticket {
  height: 98%;
}

.main-view-ticket .ant-tabs-content {
  position: inherit;

}

.newticket-view .conversation-type-box form .form-group {
  /* position: absolute;
  bottom: 0;
  width: -webkit-fill-available;
  margin-right: 15px; */
  bottom: 0;
  position: fixed;
  width: calc(100% - 685px);
}

.detail-audit-table .ant-table-content table tr {
  height: 5rem;
  border: 1px solid #E2E8F0;
}

.detail-audit-table .anticon svg {
  height: 12px;
  color: #000000;
  margin-top: 5px;
}


.detail-audit-table .audit-detail-box .ant-table {
  height: 400px !important;
}

.detail-audit-table .audit-detail-box select {
  height: 2.75rem !important;
  width: 11.5rem !important;
  border: 1px solid #757575 !important;
}

.audit-detail-box select::placeholder {
  color: #757575 !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
}

.audit-name-select {
  height: 3.125rem;
  padding: 0.625rem 1.5rem;
  border: 1px solid #CBCBCB;
  position: absolute;
  bottom: 0;
  margin-right: 15px;
  width: -webkit-fill-available;
}

/* .config-clientlist::-webkit-scrollbar,
.clientlist::-webkit-scrollbar,
.configuration-tab-list.projects-list::-webkit-scrollbar {
  display: none;
} */
.config-clientlist::-webkit-scrollbar {
  scrollbar-width: thin;
}

.config-clientlist {
  height: 300px;
  overflow-y: scroll;
}

.config-clientlist::-webkit-scrollbar,
.clientlist::-webkit-scrollbar,
.configuration-tab-list.projects-list::-webkit-scrollbar {
  width: 5px;
  background-color: #f1f1f1;
}

.config-clientlist::-webkit-scrollbar-thumb,
.clientlist::-webkit-scrollbar-thumb,
.configuration-tab-list.projects-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #888;
}

.config-clientlist,
.clientlist,
.configuration-tab-list.projects-list {
  scrollbar-color: #f1f1f1 #888;
}

.clientlist {
  height: 400px;
  overflow-y: scroll;
}

.configuration-tab-list.projects-list {
  height: 460px;
  overflow-y: scroll;
}

.custom-row.h-100 {
  height: 100% !important;
}

.industries-tab .delete-btn {
  color: #0691EF;
  background-color: #E2F1FC;
  border-color: #E2F1FC;
  border-radius: 0.375rem;
  width: 5.438rem;
  height: 3rem;
  font-weight: 600;
}

#myModalIndustryadd .modal-dialog.industries-modal .common-form-fields .active .radio {
  border: 1px solid #0691EF;
  width: 39.813rem;
}

#myModalIndustryadd .common-form-fields .radio {
  border: 1px solid #757575;
  padding: 10px 0px;
  border-radius: 6px;
}

.modal-dialog.scope-modal .common-form-fields .radio {
  border: 1px solid #757575;
  padding: 10px 0px;
  border-radius: 6px;
  width: 100% !important;
  /* overflow-x: auto; */
  /* height: 5.438rem; */
}

/* .portlet-footer.portlet-footer-bordered.scopebtn {
  position: absolute;
  bottom: 20%;
  width: 100%;
  right: 0;
} */

.modal-dialog.create-subticket-modal .ant-drawer-content-wrapper {
  width: 50% !important;
}

.industries-individual input {
  border: 1px solid #757575 !important;
  border-radius: 0.5rem !important;
  height: 3rem;
  color: #757575;
  font-weight: 500;
  font-size: 1rem;
}

.modal-dialog.industries-modal .radio .text-grey {
  color: #757575;
  font-weight: 500;
  font-size: 1rem;
}

.industries-csv-file {
  color: #757575;
  font-size: 1rem;
  font-weight: 500;
}

.industries-box input {
  border: 1px solid #757575;
  border-radius: 6px;
  padding: 12px 16px;
  height: 3rem;
  color: #A3A3A3 !important;
    font-weight: 500;
    font-size: 1rem;
}


.modal.right.bug-modal .industries-modal {
  position: fixed;
  margin: auto;
  width: 45%;
  height: 100%;
  transform: translate3d(0%, 0, 0);
}

.modal.right.fade.bug-modal .industries-modal {
  right: 0px;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.industries-choose-file {
  background-color: #E2F1FC;
  border-radius: 0.375rem;
  border: 1px solid #E2E8F0;
  /* width: 50rem; */
  height: 3.813rem;
}


element.style {
  width: 736px;
}

.ant-drawer-right>.ant-drawer-content-wrapper {
  top: 0;
  right: 0;
  bottom: 0;
  box-shadow: -6px 0 16px -8px rgb(0 0 0 / 8%), -9px 0 28px 0 rgb(0 0 0 / 5%), -12px 0 48px 16px rgb(0 0 0 / 3%);
}

.add-industries .drawer-content-wrapper {
  width: 40% !important;
}

.edit-industries .drawer-content-wrapper {
  width: 30% !important;
}

.add-industries .ant-drawer-close {
  position: absolute;
  right: 20px;
  font-size: 12px;
  color: #757575;
}

.edit-industries .ant-drawer-close {
  position: absolute;
  right: 4px;
  font-size: 12px;
  color: #757575;
}

.edit-industries .ant-drawer-title {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 30px;
  color: #212121;
}

.add-industries .ant-drawer-title {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 30px;
  color: #212121;
}

.industries-form .industries-ticket-btn {
  position: fixed;
  bottom: 0;
  z-index: 999;
  width: -webkit-fill-available;
}

.add-industries .ant-drawer-body {
  padding: 8px;
  margin-top: 8px;
}

.edit-industries .ant-drawer-body {
  padding: 0;
}

.industries-choose-file .btn.btn-primary {
  border-radius: 0.125rem;
  width: 7.25rem;
  height: 2.313rem;
  padding: 0.5rem 1rem;
  color: #FFFFFF !important;
  font-size: 0.875rem;
}

.industries-modal .or {
  color: #757575;
  font-size: 1rem;
  font-weight: 700;
}

.industries-individual label {
  font-size: 1rem;
  font-weight: 500;
  color: #212121;
}


/* .bug-modal .portlet-footer.industries-btn {
  position: fixed;
  bottom: 35px;
  right: 15px;
  width: -webkit-fill-available;
} */

.client-form label,
.project-form label {
  font-weight: 500;
  font-size: 1rem;
  color: #424242;
}

.client-form input,
.project-form input,
.client-form select,
.project-form select,
.project-form .strat-date input {
  border: 1px solid #424242 !important;
  border-radius: 8px !important;
  height: 3.5rem;
}

.client-form input::placeholder,
.project-form input::placeholder,
.project-form .strat-date input,
.client-form select,
.project-form select,
.project-form input {
  font-weight: 500;
  font-size: 1rem;
  color: #424242;
}

.client-form select,
.project-form select,
.project-credit input {
  padding-left: 15px;
}

.client-form .client-choose-file,
.project-form .project-choose-file,
.profile-portlet .personal-choose-file {
  background-color: #E2F1FC;
  border-radius: 0.375rem !important;
  border: 1px solid #E2E8F0 !important;
  height: 3.813rem;
}

.theme-light .project-form .strat-date input:focus {
  border: 1px solid #424242 !important;
}

.industries-table th:last-child {
  width: 100px;
}

.department-table th:last-child {
  width: 107px;
}

.client-table th:nth-child(3) {
  min-width: 100px;
}

.client-table th:nth-child(5) {
  min-width: 100px;
}

.project-table th:nth-child(3),
.project-table th:nth-child(4) {
  min-width: 115px;
}

.project-table th:nth-child(5) {
  min-width: 135px;
}

.project-table th:nth-child(6) {
  min-width: 210px;
}

.project-table th:nth-child(2) {
  min-width: 220px;
}

.assign-team-member-table th:nth-child(6),
.assign-team-member-table th:nth-child(7) {
  min-width: 155px;
}

.assign-team-member-table th:nth-child(2) {
  min-width: 165px;
}

.assign-team-member-table th:nth-child(2) {
  min-width: 140px;
}

.assign-team-member-table th:nth-child(8) {
  min-width: 130px;
}

#myModalIndustryadd .modal-dialog.scope-modal.idustries-modal .common-form-fields .active .radio {
  border: 1px solid #0691EF;
  width: 39.813rem;
  /* height: 16.063rem; */
}

.bug-modal .modal-dialog.scope-modal.idustries-modal .portlet-footer {
  position: fixed !important;
  bottom: 3% !important;
  width: 100% !important;
  right: 0;
}

.evaluation-modal .ant-drawer-body {
  overflow: inherit;
}

.ga-calender .click span {
  color: #fff;
  font-size: 12px;
}

.campaign-stepper .Mui-completed .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #59d866;
}

.campaign-stepper .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #ffbc4a;
}

.campaign-stepper text {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.campaign-dropdown .dropdown-submenu {
  position: relative;
  display: block;
  left: 0 !important;
  /* overflow-y: auto;
  height: 200px; */
}

.campaign-dropdown ::-webkit-scrollbar {
  width: 4px;
  height: 2em;
}

.campaign-dropdown .dropdown-submenu .dropdown-menu {
  top: 0 !important;
  margin-top: -1px !important;
}

.campaign-dropdown .dropdown-menu.show {
  margin-top: 68px !important;
}

.campaign-dropdown .btn-secondary,
.campaign-dropdown .btn-secondary:hover,
.campaign-dropdown .btn-secondary:active {
  background-color: #ffffff !important;
  background: #ffffff !important;
  color: #212121 !important;
}

.campaign-dropdown .dropdown-item,
.campaign-dropdown .dropdown-item:hover,
.campaign-dropdown .dropdown-item:active {
  background-color: #ffffff !important;
  background: #ffffff !important;
  color: #212121 !important;
}

.camp-tooltip {
  text-decoration: none;
  position: relative;
}

.camp-tooltip span {
  display: none;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  color: black;
  background: white;
}

.camp-tooltip span img {
  float: left;
  margin: 0px 8px 8px 0;
}

.camp-tooltip:hover span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: auto;
  /* max-width: 320px;
  min-height: 128px; */
  border: 1px solid black;
  margin-top: 12px;
  margin-left: 32px;
  overflow: hidden;
  padding: 8px;
}

.ant-pagination-total-text {
  flex: 1;
}

.ranking-campaing-table .ant-table-cell-row-hover {
  background: none !important;
}

.bug-modal .portlet-footer.edit-department-save {
  position: fixed;
  bottom: 0px;
  right: 15px;
  width: -webkit-fill-available;
}

.scope-edit-icon.edit-icon,
.scope-trash-icon.trash-icon {
  color: #212121 !important;
}

/* .config-universal-tab .nav-item:first-child, .config-universal-tab .nav-item:first-child.active{
  color: #0691EF;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 27px;
} */

.nav-lines .nav-link.active {
  color: #2196f3;
  border-bottom-color: #2196f3;
  font-weight: 600;
  font-size: 1.125rem;
  border-bottom: 2px solid #0691EF;
  border-width: 2px !important;
  cursor: pointer;
}

.nav-lines .nav-item.show .nav-link,
.nav-item.nav-link {
  color: #A3A3A3;
  font-weight: 600;
  font-size: 1.125rem;
}

.competitors-table th:last-child,
.assign-team-url-table th:last-child,
.assign-team-competitors-table th:last-child,
.assign-team-keyword-table th:last-child,
.url-table th:last-child,
.keywordcategory-table th:last-child,
.seoaudit-table th:last-child,
.organizationtable th:last-child {
  width: 95px;
}

.organizationtable th:nth-child(2) {
  width: 135px;
}


.name-icon {
  background-color: #e2f1fc;
  border-color: #4baff3;
  /* display: block; */
  border-radius: 100%;
  /* background: #EEEEEE; */
  /* color: #757575; */
  width: 24px;
  height: 24px;
  line-height: 20px;
  border: 1px solid #4baff3;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.name-icon span {
  color: #4baff3;
}

.text-blue-light {
  color: #4baff3;
}

/* .editor-border {
  border-right: 15px solid rgba(220, 220, 220, 0.8);
} */

.badge-article {
  background-color: #ffffff;
  color: #4baff3;
}

.right-arrow {
  transform: rotate(-90deg);
  height: 50%;
}

.drawer-right {
  background-color: #ffffff;
  position: absolute;
  height: 100%;
  top: 0px;
  left: 0px;
}

/* .ranking-campaing-table .ant-table-tbody td {
  border: 0;
} */

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.scope-table th:last-child {
  min-width: 95px;
}

.keyword-msg span {
  color: red;
}


.requirement-progreesbar .progress-bar {
  background: #0691EF;
  border-radius: 8px 0px 0px 8px;
}

.dashboard-msg p {
  color: #212121;
  font-weight: 500;
}

.dashboard-msg span {
  color: #575757;
  font-weight: 500;
}

.dashboard-msg {
  margin-top: 32%;
}

.legend-dot {
  background: #25e6a5;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
}

.legend-dot.blue {
  background: #008ffb;
}
.dapa-table  .css-1okebmr-indicatorSeparator {
  background-color: transparent;
}

.dapa-table  .css-tlfecz-indicatorContainer {
  color: #212121; }

  .dapa-table  select:focus-visible {
    border: 1px solid hsl(0, 0%, 80%) !important;
    background: #ffff;
}

.dapa-table .css-2613-menu{
	display:flex !important;
}

.no-record-auditImp{
  height: 365px;
  font-size: 20px;
  font-weight: 700;
  color: #ececec;
}

.overall-chart-highlight-green {
  opacity: 1 !important;
  fill: #E4FFED;
}

.overall-chart-highlight-grey {
  opacity: 1 !important;
  fill: #E2E8F0;
}

.overall-chart-highlight-red {
  opacity: 1 !important;
  fill: #FFEBEF;
}

.overall-table-highlight-grey {  
  background: #E2E8F0 !important;
}

.overall-table-highlight-green {  
  background: #E4FFED !important;
}

.overall-table-highlight-red {  
  background: #FFEBEF !important;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.aside-body .simplebar-wrapper {
  height: 70vh;
  display: inline-block;
  }

  .cms-dashboard-progress .ant-progress-inner{
    height: 50px !important;
    width: 50px !important;
  }

  .btn-drag-drop{
    background: #2b85e2;
    color: white;
    padding: 10px;
    border-radius: 4px ;
  }

  .serp_button {
    font-weight: 600;
    line-height: 24px;
    font-size: 1rem;
    border-radius: 5px;
    margin-left: 10px;
  }
  /* .widget15-compact{
    width: 49% !important;
  } */
/* 
  @media screen and (max-width: 1440px) {
    .serp-btn-grp .serp_button:nth-child(3){
      margin-left: 10px ;

    }
    
  }
  @media screen and (max-width: 1329px) {
    .serp-btn-grp .serp_button:nth-child(3){
      margin-top: 10px ;

    }
    
  }
  @media screen and (max-width: 1066px) {
    .serp-btn-grp .serp_button:nth-child(2){
      margin-top: 10px ;

    }
    
  }
  @media screen and (max-width: 1024px) {
    .serp-btn-grp .serp_button:nth-child(3), .serp-btn-grp .serp_button:nth-child(1){
      margin-top:10px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .serp-btn-grp{
      margin-top: 10px;
    }
    .serp-btn-grp .serp_button:nth-child(3){
      margin: 0 0 0 10px;
    }
    .serp-btn-grp .serp_button:nth-child(3){
      margin-top: 10px;
    }
    
  }
  @media screen and (max-width: 425px) {
    .serp-btn-grp .serp_button:nth-child(3){
      margin-top: 10px;
    }
   
  }
  @media screen and (max-width: 375px) {
    .serp-btn-grp .serp_button:nth-child(2){
      margin-top: 10px;
    }
    .serp-btn-grp .serp_button:nth-child(3){
      margin-right: 0px;
    }
   
  } */

  /* @media screen and (max-width: 2528px) {
      .widget15-compact{
    min-width: 78% !important;
  }
  }
  @media screen and (max-width: 2448px) {
    .widget15-compact{
  min-width: 77% !important;
}
}
@media screen and (max-width: 2464px) {
  .widget15-compact{
min-width: 76% !important;
}
} */

